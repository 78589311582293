import { useMutation, useQueryClient } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { runWorkflowSync } from "../api/run-workflow-sync";

export const useRunWorkflowSync = (opts?: any) => {
  const { token } = useClerkToken();
  const qc = useQueryClient();

  return useMutation(({ id }: { id: string }) => runWorkflowSync(token, id), {
    ...(opts || {}),
    onSuccess: () => {
      qc.invalidateQueries(["WORKFLOWS"]);
      qc.invalidateQueries(["OP_PREVIEW"]);

      if (opts?.onSuccess) {
        opts.onSuccess();
      }
    },
  });
};
