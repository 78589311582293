import { API_URL } from "./auth";
import { Schedule } from "./get-schedules";

export const getScheduleByQuery = async ({
  token,
  id,
}: {
  token: string | null;
  id: string;
}): Promise<Schedule | null> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const resp = await fetch(API_URL + `/api/jobs-user/${id}`, {
    method: "GET",
    headers,
  });

  if (resp.status === 404) {
    return null;
  }

  if (resp.status !== 200) {
    let error = `Could not get schedule for ${id} due to ${resp.status} HTTP error code`;

    if (resp.status < 500 && resp.status >= 400) {
      const json = await resp.json();
      error = json["detail"];
    }

    throw new Error(error);
  }

  return (await resp.json())["data"];
};
