import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { getUserScopes } from "../api/get-user-scopes";

export const useUserScopes = (opts?: any) => {
  const { token } = useClerkToken();

  return useQuery(["USER_SCOPES"], () => getUserScopes(token), {
    enabled: !!token,
    refetchInterval: 5_000,
    refetchOnWindowFocus: "always",
    refetchIntervalInBackground: false,
    ...(opts || {}),
  });
};
