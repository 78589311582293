import {
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
  Box,
  VStack,
  HStack,
  IconButton,
  Tooltip,
  Heading,
  useToast,
  Input,
} from "@chakra-ui/react";
import { useSheetFiles } from "./hooks/use-sheet-files";
import { RepeatIcon } from "@chakra-ui/icons";
import SheetDetails from "./SheetDetails";
import { useSyncSheets } from "./hooks/use-sync-sheets";
import { durationDelta, msToTime } from "./utils";
import { useRequestSyncSheetFile } from "./hooks/use-request-sync-sheets";
import React from "react";
import { SheetFile } from "./api/get-sheet-files";

const SheetAccordionList = () => {
  const toast = useToast();
  const [search, setSearch] = React.useState<string | null>(null);

  const {
    data: rawData,
    refetch: refetchSheetFiles,
    isLoading,
    isRefetching,
  } = useSheetFiles(
    {
      q: search && search.length > 0 ? search : undefined,
      limit: 25,
    },
    {
      onError: (error: Error) => {
        toast({
          title: "Could not fetch sheet list",
          description: error.toString(),
          duration: null,
          status: "error",
          isClosable: true,
        });
      },
      refetchInterval: 5000,
    },
  );

  const {
    data: sync,
    isLoading: isSyncing,
    refetch,
  } = useSyncSheets({
    refetchInterval: 5000,
  });

  const {
    mutate: requestSyncSheetFile,
    isLoading: isRequestingSync,
    isSuccess: isRequestSyncSuccess,
  } = useRequestSyncSheetFile();

  React.useEffect(() => {
    if (isRequestSyncSuccess) {
      refetch();
    }
  }, [isRequestSyncSuccess, refetch]);

  React.useEffect(() => {
    let to: any;

    if (search !== null) {
      to = setTimeout(() => {
        refetchSheetFiles();
      }, 100);
    }

    return () => {
      if (to) {
        clearTimeout(to);
      }
    };
  }, [search, refetchSheetFiles]);

  const isSyncInLastPeriod =
    sync &&
    sync.finished_at &&
    new Date(sync.finished_at) > new Date(Date.now() - 5 * 60 * 1000);

  const isResyncDisabled =
    isRequestingSync ||
    isLoading ||
    isRefetching ||
    isSyncing ||
    isSyncInLastPeriod;

  const syncValid = sync && ["enqueued", "started"].includes(sync.status);

  let data: SheetFile[] = rawData?.data || [];

  return (
    <VStack spacing={1} alignItems="start">
      <HStack spacing={1} m={2} width="-webkit-fill-available">
        <VStack spacing={1} sx={{ flex: 1 }} alignItems="start">
          <Heading size="md">
            Your sheet list {rawData?.total ? `(${rawData.total})` : ""}
          </Heading>
          {sync && (
            <Text fontSize="xs">
              Sync {sync.status}{" "}
              {sync.finished_at
                ? ` ${durationDelta(new Date(sync.finished_at))}`
                : ""}
              {sync.elapsed_ms ? ` in ${msToTime(sync.elapsed_ms)}` : ""}
            </Text>
          )}
        </VStack>
        <Tooltip label="Refresh all the sheet list (at most every 5 minutes)">
          <IconButton
            isDisabled={isResyncDisabled || syncValid}
            isLoading={isRequestingSync}
            onClick={() => requestSyncSheetFile()}
            variant="ghost"
            size="xs"
            fontSize="12px"
            aria-label="Refresh sheet list"
            icon={<RepeatIcon />}
          />
        </Tooltip>
      </HStack>
      <Input
        size="sm"
        placeholder="Fuzzy search sheets"
        value={search || undefined}
        onChange={({ target }) => setSearch(target.value)}
      />
      <Box
        h={["200px", null, "auto"]}
        flex={1}
        flexDir="column"
        overflow="auto"
        w="100%"
        css={{
          "&::-webkit-scrollbar": {
            height: "var(--chakra-sizes-1)",
            width: "var(--chakra-sizes-1)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "var(--chakra-colors-gray-400)",
          },
        }}
      >
        {data && (
          <Accordion allowMultiple>
            {data.map((sheet) => (
              <AccordionItem key={sheet.id}>
                {({ isExpanded }) => (
                  <Box>
                    <AccordionButton>
                      <Text fontSize="xs" noOfLines={1}>
                        {sheet.title}
                      </Text>
                      <Spacer />
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <SheetDetails id={sheet.id} enabled={isExpanded} />
                    </AccordionPanel>
                  </Box>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </Box>
    </VStack>
  );
};

export default SheetAccordionList;
