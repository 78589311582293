import { API_URL } from "./auth";

export type ScheduleParams = {
  query_id: string;
  schedule: string;
  description?: string;
};

export const deleteSchedule = async ({
  token,
  id,
}: {
  token: string | null;
  id: string;
}) => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const resp = await fetch(API_URL + `/api/jobs/${id}`, {
    method: "DELETE",
    headers,
  });

  // XD
  if (resp.status !== 204) {
    let error = `Could not delete schedule ${id} due to ${resp.status} HTTP error code`;

    if (resp.status < 500 && resp.status >= 400) {
      const json = await resp.json();
      error = json["detail"];
    }

    throw new Error(error);
  }

  return;
};
