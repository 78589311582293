import { JobSyncDetails } from "../types";
import { API_URL } from "./auth";
import { SheetFile } from "./get-sheet-files";

export type SheetFileWorksheetDetails = {
  id: string;
  index: number;
  row_count: number;
  exact_row_count?: number;
  column_count: number;
  header_range: string;
  header_values: string[];
  title: string;
};

export type SheetFileDetails = SheetFile & {
  worksheet: SheetFileWorksheetDetails;
  worksheets: SheetFileWorksheetDetails[];
  sync: JobSyncDetails | null;
};

export const getSheetFileDetails = async (
  token: string | null,
  sheetId: string,
  worksheetId?: number,
): Promise<SheetFileDetails> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const query = new URLSearchParams();

  if (worksheetId) {
    query.append("ws_id", worksheetId.toString());
  }

  const resp = await fetch(
    API_URL + `/api/sheet-files/${sheetId}?${query.toString()}`,
    {
      method: "GET",
      headers,
    },
  );

  if (resp.status !== 200) {
    throw new Error(
      `Could not fetch sheet file details due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
