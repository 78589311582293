import { API_URL } from "./auth";

export type SheetFile = {
  id: string;
  url: string;
  title: string;
  created_at: Date;
  updated_at: Date;
  user_id: string;
};

export const getSheetFiles = async (
  token: string | null,
  q?: string,
  limit?: number,
  offset?: number,
): Promise<{
  data: SheetFile[];
  total: number;
}> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const qs = new URLSearchParams();

  if (q) {
    qs.append("q", q);
  }

  if (limit) {
    qs.append("limit", limit.toString());
  } else {
    qs.append("limit", "1000");
  }

  if (offset) {
    qs.append("offset", offset.toString());
  }

  const qsUrl = `?${qs.toString()}`;

  const resp = await fetch(API_URL + `/api/sheet-files${qsUrl}`, {
    method: "GET",
    headers,
  });

  if (resp.status !== 200) {
    const json = await resp.json();

    throw new Error(
      json?.detail ||
        `Could not fetch sheet files due to ${resp.status} HTTP error code`,
    );
  }

  const data = await resp.json();

  return {
    data,
    total: parseInt(resp.headers.get("X-Total-Count") || data.length, 10),
  };
};
