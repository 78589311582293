import * as React from "react";
import { ExternalLinkIcon, RepeatClockIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  IconButton,
  Link,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { UserSavedQuery } from "./api/get-query";
import { useUserUsage } from "./hooks/use-user-usage";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ScheduleParams, createSchedule } from "./api/create-schedule";
import cron from "cron-validate";
import { useClerkToken } from "./providers/clerk-token";
import { getScheduleByQuery } from "./api/get-schedule-by-query";
import { deleteSchedule } from "./api/delete-schedule";
import ScheduleRunsTable from "./ScheduleRunsTable";

type Props = {
  query?: UserSavedQuery;
};

const ScheduleQueryButton = ({ query }: Props) => {
  const { token } = useClerkToken();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [params, setParams] = React.useState<ScheduleParams>({} as any);

  const qc = useQueryClient();

  const toast = useToast();

  const {
    data: maybeSchedule,
    refetch,
    remove,
  } = useQuery(
    ["GET_SCHEDULE_USER", query?.id],
    () => getScheduleByQuery({ token, id: query?.id! }),
    {
      enabled: !!token && !!query?.id,
      retry: false,
    },
  );

  const { isLoading: isCreating, mutate: createScheduleMt } = useMutation(
    createSchedule,
    {
      onError: (err: Error) => {
        toast({
          title: "Could not create query schedule",
          description: err.toString(),
          duration: 10_000,
          status: "error",
          isClosable: true,
        });
        remove();
      },
      onSuccess: () => {
        toast({
          title: "Query scheduled created!",
          description: `You've successfully scheduled ${query?.name}`,
          duration: 2_000,
          status: "success",
          isClosable: true,
        });

        remove();
        qc.invalidateQueries(["GET_SCHEDULE_USER"]);
      },
    },
  );

  const { mutate: deleteScheduleMt, isLoading: isDeleting } = useMutation(
    deleteSchedule,
    {
      onError: (err: Error) => {
        toast({
          title: "Could not delete schedule quer",
          description: err.toString(),
          duration: 10_000,
          status: "error",
          isClosable: true,
        });
        remove();
      },
      onSuccess: () => {
        toast({
          title: "Schedule deleted!",
          description: `You've successfully deleted schedule for ${query?.name}`,
          duration: 2_000,
          status: "success",
          isClosable: true,
        });

        remove();
        qc.invalidateQueries(["GET_SCHEDULE_USER"]);
      },
    },
  );

  const { data: usage, isLoading: isUsageLoading } = useUserUsage();

  React.useEffect(() => {
    if (!query?.id) return;

    refetch();

    setParams({
      schedule: "0 * * * *",
      query_id: query?.id!,
    });
  }, [query?.id, setParams, refetch]);

  React.useEffect(() => {
    if (!maybeSchedule || !query) return;

    setParams({
      query_id: query?.id,
      description: maybeSchedule.description,
      schedule: maybeSchedule.schedule,
    });
  }, [maybeSchedule, query, setParams]);

  const cronValue = cron(params.schedule || "* * * * *");
  const isValid = cronValue.isValid() || !params.query_id || !!maybeSchedule;

  const handleCreate = () => {
    if (!query || !token) return;
    if (!params.query_id || !params.schedule || !isValid) return;

    createScheduleMt({
      token,
      data: {
        schedule: params.schedule,
        description: params.description,
        query_id: query.id,
      },
    });
  };

  const handleDelete = () => {
    if (!maybeSchedule || !token) return;

    deleteScheduleMt({
      token,
      id: maybeSchedule.id,
    });
  };

  return (
    <Box>
      <Tooltip
        label={
          query
            ? "PRO feature: Schedule query run and export"
            : "PRO feature: Select a query to be able to schedule run and export"
        }
        placement="bottom-start"
      >
        <IconButton
          isLoading={isUsageLoading || isCreating}
          isDisabled={!query || usage?.type !== "PRO" || isCreating}
          onClick={onOpen}
          fontSize="12px"
          aria-label="Schedule query"
          icon={<RepeatClockIcon />}
        />
      </Tooltip>
      {query && (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay
            bg="blackAlpha.200"
            backdropFilter="blur(10px) hue-rotate(45deg)"
          />
          <ModalContent>
            <ModalHeader>
              Schedule <b>{query.name}</b>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={2}>
                <Text fontSize="xs">
                  Here you will be able to schedule the query{" "}
                  <b>{query.name}</b> with the schedule you want.
                </Text>
                <VStack w="100%">
                  <Text fontSize="xx-small">
                    A{" "}
                    <Link
                      href={`https://crontab.guru/#${params.schedule?.replace(" ", "_")}`}
                      variant="solid"
                      color="purple.800"
                      isExternal
                    >
                      valid CRON string
                      <ExternalLinkIcon ml={1} />
                    </Link>{" "}
                    defining the schedule
                  </Text>
                  <Input
                    required
                    isDisabled={!!maybeSchedule}
                    placeholder="CRON expression, e.g. 0 * * * *"
                    fontSize="xs"
                    value={params.schedule}
                    onChange={(e) =>
                      setParams((p) => ({ ...p, schedule: e.target.value }))
                    }
                  />
                  {!cronValue.isValid() &&
                    cronValue.getError().map((e, ix) => (
                      <Text key={`error-${ix}`} fontSize="xx-small" color="red">
                        {e}
                      </Text>
                    ))}
                </VStack>
                <VStack w="100%">
                  <Text fontSize="xx-small">
                    An optional description of the schedule
                  </Text>
                  <Input
                    isDisabled={!!maybeSchedule}
                    placeholder="Some description..."
                    fontSize="xs"
                    value={params.description || ""}
                    onChange={(e) =>
                      setParams((p) => ({ ...p, description: e.target.value }))
                    }
                  />
                </VStack>
              </VStack>
              {!!maybeSchedule && (
                <Text fontSize="xs" sx={{ mt: 4 }}>
                  <b>Schedule already created</b> for this query at{" "}
                  {new Date(maybeSchedule.update_time).toLocaleString()}. If you
                  wish to change the schedule, please <b>delete it</b> and
                  create it again.
                </Text>
              )}
              {query?.id && (
                <Box mt={4} maxH="400px" overflowY="auto">
                  <ScheduleRunsTable queryId={query?.id} />
                </Box>
              )}
            </ModalBody>
            <ModalFooter>
              {maybeSchedule && (
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={handleDelete}
                  isDisabled={isDeleting || isCreating}
                  isLoading={isDeleting}
                  mr={3}
                >
                  Delete
                </Button>
              )}
              <Button
                variant="solid"
                colorScheme="purple"
                onClick={handleCreate}
                isDisabled={
                  !isValid || isCreating || !!maybeSchedule || isDeleting
                }
                isLoading={isCreating}
              >
                Schedule
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default ScheduleQueryButton;
