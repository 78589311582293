import { API_URL } from "./auth";
import { UserSavedQuery } from "./get-query";

export type QueryParams = {
  name: string;
  query: string;
};

export const saveQuery = async ({
  token,
  query,
}: {
  token: string | null;
  query: QueryParams;
}): Promise<UserSavedQuery> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const resp = await fetch(API_URL + `/api/queries`, {
    method: "POST",
    headers,
    body: JSON.stringify(query),
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not save query due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
