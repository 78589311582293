import { SignedIn, SignedOut } from "@clerk/clerk-react";
import {
  Box,
  Center,
  Container,
  Heading,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import Explorer from "./Home";
import Landing from "./Landing";
import Header from "./Header";
import { Route, Routes } from "react-router-dom";
import Workflows from "./WorkflowsNew";
import { useUser } from "./hooks/use-user";
// import JobEvents from "./JobEvents";

function ExplorerApp() {
  return (
    <Box background="gray.50" minH="100vh">
      <Container maxW="container.2xl">
        <SignedOut>
          <Header />
          <Landing />
        </SignedOut>
        <SignedIn>
          <Header signedIn />
          <Explorer />
        </SignedIn>
      </Container>
    </Box>
  );
}

function WorkflowsApp() {
  return (
    <Box background="gray.50" minH="100vh" overflowY="visible">
      <Container maxW="container.2xl">
        <SignedOut>
          <Header />
          <Landing />
        </SignedOut>
        <SignedIn>
          <Header signedIn />
          <Workflows />
        </SignedIn>
      </Container>
    </Box>
  );
}

const AppRouter = () => {
  return (
    <Routes>
      <Route index element={<ExplorerApp />} />
      <Route path="/workflows" element={<WorkflowsApp />} />
    </Routes>
  );
};

const App = () => {
  const { isLoading } = useUser();

  if (isLoading) {
    return (
      <Box background="gray.50">
        <Container maxW="container.2xl">
          <Center h="100vh">
            <VStack alignItems="center" spacing={2}>
              <Heading size="md">Welcome!</Heading>
              <Text fontSize="md">
                Please wait while we configure your account...
              </Text>
              <Spinner />
            </VStack>
          </Center>
        </Container>
      </Box>
    );
  }

  return (
    <>
      <AppRouter />
      {/* <JobEvents /> */}
    </>
  );
};

export default App;
