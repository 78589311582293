import { BlockWithData } from "../blocks.types";
import { API_URL } from "./auth";

export const getBlock = async (
  token: string | null,
  id: string,
): Promise<BlockWithData> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const resp = await fetch(API_URL + `/api/b/blocks/${id}`, {
    method: "GET",
    headers,
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not fetch block due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
