import { API_URL } from "./auth";
import { SheetFileWorksheetDetails } from "./get-sheet-file-details";

export type PatchSpreadsheetSheetParams = {
  header_range?: string;
  header_values?: string[];
};

export type PatchSpreadsheetSheetInput = {
  id: string;
  sheetId: string;
  params: PatchSpreadsheetSheetParams;
};

export const patchSpreadsheetSheet = async ({
  token,
  id,
  sheetId,
  params,
}: PatchSpreadsheetSheetInput & {
  token: string | null;
}): Promise<SheetFileWorksheetDetails> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const resp = await fetch(
    API_URL + `/api/spreadsheets/${id}/sheets/${sheetId}`,
    {
      method: "PATCH",
      headers,
      body: JSON.stringify(params),
    },
  );

  if (resp.status !== 200) {
    throw new Error(
      `Could not patch spreadsheet sheet due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
