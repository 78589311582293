import { API_URL } from "./auth";
import { JobSyncDetails } from "../types";

export const getSyncSheets = async ({
  token,
}: {
  token: string | null;
}): Promise<JobSyncDetails> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const resp = await fetch(API_URL + `/api/sheet-files-sync`, {
    method: "GET",
    headers,
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not get sheets sync due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
