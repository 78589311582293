import { API_URL } from "./auth";
import { SheetQueryResult } from "./preview-sheet-query";

export type SheetQuerySaveResult = SheetQueryResult & {
  processed_in: number;
  save_data: {
    id: string;
    name: string;
    url: string;
  };
};

export const saveSheetQuery = async ({
  token,
  name,
  query,
}: {
  token: string | null;
  name?: string;
  query: string;
}): Promise<SheetQuerySaveResult> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const resp = await fetch(API_URL + `/api/sheet-files-query-save`, {
    method: "POST",
    body: JSON.stringify({ query, name }),
    headers,
  });

  if (resp.status !== 200) {
    const json = await resp.json();
    throw new Error(
      json.detail ||
        "Could not run and save query due to unknown issue, if it keeps failing, please contact support.",
    );
  }

  const data = await resp.json();

  return {
    ...data,
    elapsed: data.elapsed || Number(resp.headers.get("X-Process-Time") || 0),
    processed_in: Number(resp.headers.get("X-Process-Time") || 0),
    ranAt: new Date(),
  };
};
