import { API_URL } from "./auth";

export type User = {
  id: string;
  email: string;
  version: string;
};

export const getUser = async (token: string | null): Promise<User> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const resp = await fetch(API_URL + "/api/user", {
    method: "GET",
    headers,
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not fetch user due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
