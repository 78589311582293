import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { upsertOp } from "../api/upsert-op";
import { OpUpsertParams } from "../blocks.types";

export const usePreviewOp = (
  data: Omit<OpUpsertParams, "dry_run">,
  opts?: any,
) => {
  const { token } = useClerkToken();

  return useQuery(
    ["OP_PREVIEW", data.id],
    () =>
      upsertOp({
        token,
        data: {
          ...data,
          dry_run: true,
        },
      }),
    {
      ...(opts || {}),
    },
  );
};
