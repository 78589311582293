import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { getBlockData } from "../api/get-block-data";

export const useBlockData = (id: string, opts?: any) => {
  const { token } = useClerkToken();

  return useQuery(["BLOCK_DATA", id], () => getBlockData(token, id), {
    ...(opts || {}),
    // Default
    enabled: !!token,
  });
};
