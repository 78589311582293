import { useSheetFileDetails } from "./hooks/use-sheet-file-details";
import BlockModal from "./BlockModal";

type Props = {
  spreadsheetId: string;
  sheetIx?: number;
  isOpen: boolean;
  onClose: () => void;
};

const SheetModal = ({ spreadsheetId, sheetIx, isOpen, onClose }: Props) => {
  const { data, isLoading } = useSheetFileDetails(spreadsheetId, sheetIx);

  if (isLoading || !data) {
    return null;
  }

  return (
    <BlockModal
      isOpen={isOpen}
      onClose={onClose}
      blockId={`${data.id}__${data.worksheet.id}__${data.user_id}`}
    />
  );
};

export default SheetModal;
