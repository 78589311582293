import { Op } from "../blocks.types";
import { API_URL } from "./auth";

export const getOp = async (token: string | null, id: string): Promise<Op> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const resp = await fetch(API_URL + `/api/b/ops/${id}`, {
    method: "GET",
    headers,
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not fetch operation due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
