import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { getUserUsage } from "../api/get-user-usage";

export const useUserUsage = (opts?: any) => {
  const { token } = useClerkToken();

  return useQuery(["USER_USAGE"], () => getUserUsage(token), {
    enabled: !!token,
    refetchInterval: 5_000,
    refetchOnWindowFocus: "always",
    refetchIntervalInBackground: false,
    ...(opts || {}),
  });
};
