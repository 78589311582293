import { API_URL } from "./auth";

type TypeUsage = {
  usage: number;
  limit: number;
};

export type UserUsage = {
  type: "FREE" | "PRO";
  count: number;
  by_type: {
    QUERY: TypeUsage;
    WORKFLOW: TypeUsage;
    AI: TypeUsage;
  };
  limit: {
    QUERY: number;
    WORKFLOW: number;
    AI: number;
  };
  billing: {
    payment_link: string;
    customer_portal: string;
  };
};

export const getUserUsage = async (
  token: string | null,
): Promise<UserUsage> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const resp = await fetch(API_URL + "/api/user/usage", {
    method: "GET",
    headers,
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not fetch user usage due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
