import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { getUser } from "../api/get-user";

export const useUser = (opts?: any) => {
  const { token } = useClerkToken();

  return useQuery(["USER"], () => getUser(token), {
    ...(opts || {}),
    // Default
    enabled: !!token,
  });
};
