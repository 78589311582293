import {
  Box,
  Button,
  Center,
  HStack,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spinner,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useUserUsage } from "./hooks/use-user-usage";
import { useSendAppEvent } from "./hooks/use-send-app-event";
import { CheckIcon } from "@chakra-ui/icons";

const Usage = () => {
  const { data, isLoading } = useUserUsage();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutate: ev } = useSendAppEvent();

  if (isLoading) {
    return <Spinner />;
  }

  if (!data) {
    return null;
  }

  const { type, billing } = data;

  let tooltipLabel = "Upgrade to PRO!";

  if (type === "PRO") {
    tooltipLabel = "Manage your subscription on Stripe";
  }

  const link = type === "PRO" ? billing.customer_portal : billing.payment_link;

  const onManageClick = () => {
    ev({
      ty: "app_click",
      data: {
        link: "stripe",
        type,
        _link: link,
      },
    });

    window.open(link, "_blank");
  };

  const onClick = () => {
    ev({
      ty: "app_click",
      data: {
        link: "usage",
        type,
      },
    });

    onOpen();
  };

  let progressColors = {
    QUERY: "green",
    WORKFLOW: "green",
    AI: "green",
  };

  const computeColor = (val: number) => {
    if (val > 0.7) {
      return "red";
    } else if (val > 0.5) {
      return "yellow";
    } else if (val > 0.25) {
      return "orange";
    } else {
      return "blue";
    }
  };

  if (type !== "PRO") {
    progressColors = {
      QUERY: computeColor(data.by_type.QUERY.usage / data.by_type.QUERY.limit),
      WORKFLOW: computeColor(
        data.by_type.WORKFLOW.usage / data.by_type.WORKFLOW.limit,
      ),
      AI: computeColor(data.by_type.AI.usage / data.by_type.AI.limit),
    };
  }

  return (
    <Box>
      <Tooltip label="Manage your SheetSQL subscription here!">
        <Button
          variant="ghost"
          onClick={onClick}
          colorScheme={type === "PRO" ? "purple" : "blue"}
        >
          <HStack spacing={1} alignItems="center">
            <Tag colorScheme={type === "PRO" ? "purple" : "blue"}>
              <TagLabel>{type}</TagLabel>
            </Tag>
            <Text fontSize="xs">{data.count} credits used</Text>
          </HStack>
        </Button>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay
          zIndex={100}
          bg="blackAlpha.200"
          backdropFilter="blur(10px) hue-rotate(45deg)"
        />
        <ModalContent>
          <ModalHeader>Your plan: {type}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} m={1} alignItems="stretch">
              <VStack spacing={2} alignItems="flex-start">
                <Text>
                  You used a total of <b>{data.count} credits</b> this month.
                </Text>
                <Text fontSize="xs">Your credit usage breakdown:</Text>
                <HStack width="100%" spacing={2} alignItems="center">
                  <Tooltip label="Each successful query in the explorer, and queries ran in workflows (not skipped) will use 1 credit.">
                    <Text minW="80px" className="underline-dots">
                      Queries
                    </Text>
                  </Tooltip>
                  <Box flex="1">
                    <Progress
                      colorScheme={progressColors.QUERY}
                      value={
                        type === "PRO"
                          ? 100
                          : (data.by_type.QUERY.usage /
                              data.by_type.QUERY.limit) *
                            100
                      }
                      size="md"
                    />
                  </Box>
                  <Text fontSize="xs" minW="50px" align="right">
                    {data.by_type.QUERY.usage} /{" "}
                    {type === "PRO" ? "∞" : data.by_type.QUERY.limit}
                  </Text>
                </HStack>
                <HStack width="100%" spacing={2} alignItems="center">
                  <Tooltip label="Each successful workflow run will use 1 credit.">
                    <Text minW="80px" className="underline-dots">
                      Workflows
                    </Text>
                  </Tooltip>
                  <Box flex="1">
                    <Progress
                      colorScheme={progressColors.WORKFLOW}
                      value={
                        type === "PRO"
                          ? 100
                          : (data.by_type.WORKFLOW.usage /
                              data.by_type.WORKFLOW.limit) *
                            100
                      }
                      size="md"
                    />
                  </Box>
                  <Text fontSize="xs" minW="50px" align="right">
                    {data.by_type.WORKFLOW.usage} /{" "}
                    {type === "PRO" ? "∞" : data.by_type.WORKFLOW.limit}
                  </Text>
                </HStack>
                <HStack width="100%" spacing={2} alignItems="center">
                  <Tooltip label="Using either the explain or generate feature will use 1 credit each time.">
                    <Text minW="80px" className="underline-dots">
                      AI
                    </Text>
                  </Tooltip>
                  <Box flex="1">
                    <Progress
                      colorScheme={progressColors.AI}
                      value={
                        type === "PRO"
                          ? 100
                          : (data.by_type.AI.usage / data.by_type.AI.limit) *
                            100
                      }
                      size="md"
                    />
                  </Box>
                  <Text fontSize="xs" minW="50px" align="right">
                    {data.by_type.AI.usage} /{" "}
                    {type === "PRO" ? "∞" : data.by_type.AI.limit}
                  </Text>
                </HStack>
              </VStack>
              {type !== "PRO" && (
                <VStack spacing={2} alignItems="flex-start">
                  <Text>
                    Upgrade to PRO for <b>$5 per month</b> and get the access
                    to:
                  </Text>
                  <List spacing={3}>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>∞ query credits</b> per month
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>∞ workflow credits</b> per month
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>
                        1000{"  "}
                        AI credits
                      </b>{" "}
                      per month
                    </ListItem>
                  </List>
                </VStack>
              )}
              <Center>
                <Button
                  variant="solid"
                  colorScheme={type === "PRO" ? "green" : "purple"}
                  onClick={onManageClick}
                  maxW="sm"
                >
                  {tooltipLabel}
                </Button>
              </Center>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Usage;
