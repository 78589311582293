export const durationDelta = (done: Date) => {
  const now = new Date();
  const delta = now.getTime() - done.getTime();

  if (delta < 1000) {
    return "just now";
  }

  if (delta < 60000) {
    return `${Math.floor(delta / 1000)}s ago`;
  }

  if (delta < 3600000) {
    return `${Math.floor(delta / 60000)}m ago`;
  }

  return `${Math.floor(delta / 3600000)}h ago`;
};

export const msToTime = (duration: number) => {
  if (duration < 1000) {
    return duration.toFixed(0) + "ms";
  }

  let seconds = (duration / 1000).toFixed(2);
  return seconds + "s";
};

export const secToTime = (duration: number) => {
  if (duration < 1) {
    return (duration * 1000).toFixed(0) + "ms";
  }

  if (duration < 60) {
    return duration + "s";
  }

  let minutes = Math.floor(duration / 60);
  let seconds = duration % 60;

  return `${minutes}m ${seconds}s`;
};
