import { API_URL } from "./auth";
import { SheetFileWorksheetDetails } from "./get-sheet-file-details";

export const getSpreadsheetSheets = async (
  token: string | null,
  sheetId: string,
): Promise<SheetFileWorksheetDetails[]> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const resp = await fetch(API_URL + `/api/spreadsheets/${sheetId}/sheets`, {
    method: "GET",
    headers,
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not fetch spreadsheet sheets due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
