import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { getWorkflow } from "../api/get-workflow";

export const useWorkflow = (id: string, opts?: any) => {
  const { token } = useClerkToken();

  return useQuery(["WORKFLOWS", id], () => getWorkflow(token, id), {
    ...(opts || {}),
    // Default
    enabled: !!token,
  });
};
