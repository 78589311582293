import * as React from "react";
import { CloseIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  HStack,
  IconButton,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useUserUsage } from "./hooks/use-user-usage";
import { useAi } from "./hooks/use-ai";
import Markdown from "react-markdown";
import { SheetSQLCodeMirrorEditor } from "./CodeMirrorEditor";
import { useQueryClient } from "react-query";

type Props = {
  query?: string;
  onChange: (q: string) => void;
  onRun: () => void;
};

const AiQueryButton = ({ query, onChange, onRun }: Props) => {
  const { data: usage, isLoading: isUsageLoading } = useUserUsage();
  const qc = useQueryClient();

  const [explainEnabled, setExplainEnabled] = React.useState(false);

  const [generateEnabled, setGenerateEnabled] = React.useState<string | null>(
    null,
  );
  const [generateClicked, setGenerateClicked] = React.useState(false);

  const allDisabled =
    !usage ||
    (usage.type !== "PRO" && usage.by_type.AI.usage >= usage.by_type.AI.limit);
  let left: string | number | null = usage
    ? usage.by_type.AI.limit - usage.by_type.AI.usage
    : null;

  if (usage?.type === "PRO") {
    left = "∞";
  }

  const {
    process: explainProcess,
    data: explainData,
    reset: resetExplain,
    cancel: cancelExplain,
  } = useAi(
    "explain",
    () => {
      setExplainEnabled(false);
      qc.invalidateQueries(["USER_USAGE"]);
    },
    explainEnabled,
    {
      content: query,
    },
  );

  let genContent = generateEnabled;

  if (!!query) {
    genContent = `Given the query: \n\n${query}\n\nGenerate a query that: \n\n${generateEnabled}`;
  }

  const {
    process: generateProcess,
    data: generateData,
    reset: resetGenerate,
    cancel: cancelGenerate,
  } = useAi(
    "generate",
    () => {
      setGenerateClicked(false);
      qc.invalidateQueries(["USER_USAGE"]);
    },
    generateClicked,
    {
      content: genContent,
    },
  );

  const explainInvisible = !query;
  const explainDisabled = explainInvisible || allDisabled;

  React.useEffect(() => {
    cancelExplain();
    setExplainEnabled(false);
    resetExplain();

    cancelGenerate();
    setGenerateClicked(false);
    setGenerateEnabled(null);
    onChange(query || "");
    resetGenerate();
  }, [query]);

  React.useEffect(() => {
    if (generateData?.length > 0) {
      onChange(generateData);
    }
  }, [generateData]);

  React.useEffect(() => {
    if (generateData.length > 0 && !generateClicked) {
      onRun();
      resetGenerate();
    }
  }, [generateData, generateClicked, resetGenerate]);

  return (
    <VStack alignItems="flex-end" width="100%">
      {explainData.length > 0 && (
        <Alert status="info" flexDir="column" p={4} borderRadius="md">
          <AlertDescription p={2}>
            <Markdown>{explainData}</Markdown>
          </AlertDescription>
        </Alert>
      )}
      {generateEnabled !== null && (
        <Box width="100%">
          <VStack alignItems="stretch" spacing={2}>
            <Text fontSize="xs" color="gray.600">
              Write a prompt to generate or adapt a query, e.g. "Show me the top
              10 most popular products". You can reference your sheets by their
              name, the prompt will automatically autocomplete them as in the
              SQL editor.
            </Text>
            <Box
              backgroundColor="purple.100"
              p={2}
              borderRadius="md"
              width="100%"
            >
              <SheetSQLCodeMirrorEditor
                value={generateEnabled}
                onChange={(s) => setGenerateEnabled(s)}
                normalMode
              />
            </Box>
          </VStack>
        </Box>
      )}
      <HStack>
        {!explainInvisible && (
          <Tooltip
            label={
              allDisabled
                ? "Upgrade to PRO for more AI credits"
                : `Explain the query (${left} credits left)`
            }
            placement="bottom-start"
          >
            <Button
              variant="outline"
              colorScheme={explainProcess.status === "open" ? "red" : "purple"}
              isLoading={isUsageLoading}
              isDisabled={explainDisabled}
              onClick={() =>
                !explainEnabled ? setExplainEnabled(true) : cancelExplain()
              }
            >
              {explainProcess.status === "open"
                ? "Stop explaining"
                : "Explain with AI 🧠"}
            </Button>
          </Tooltip>
        )}
        {generateEnabled === null ? (
          <Tooltip
            label={
              allDisabled
                ? "Upgrade to PRO for more AI credits"
                : `Generate a query from a prompt (${left} credits left)`
            }
            placement="bottom-start"
          >
            <Button
              variant="solid"
              colorScheme="purple"
              isLoading={isUsageLoading}
              isDisabled={allDisabled}
              onClick={() => {
                if (generateEnabled === null) {
                  setGenerateEnabled("");
                }
              }}
            >
              {!!query ? "Adapt with AI ✨" : "Generate with AI ✨"}
            </Button>
          </Tooltip>
        ) : (
          <HStack
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Tooltip
              label="Click to generate a query from the prompt"
              placement="bottom-start"
            >
              <Button
                aria-label="Generate"
                variant="solid"
                colorScheme="purple"
                isLoading={generateClicked}
                isDisabled={
                  (generateEnabled !== null && generateEnabled === "") ||
                  generateProcess.status === "open" ||
                  generateClicked
                }
                onClick={() => {
                  if (generateEnabled === null || generateEnabled === "") {
                    return;
                  }

                  setGenerateClicked(true);
                }}
              >
                {!!query ? "Adapt with AI ✨" : "Generate with AI ✨"}
              </Button>
            </Tooltip>
            <Tooltip label="Cancel generation" placement="bottom-start">
              <IconButton
                aria-label="Cancel"
                variant="outline"
                colorScheme="red"
                icon={<CloseIcon />}
                onClick={() => {
                  if (generateClicked || generateProcess.status === "open") {
                    cancelGenerate();
                  } else {
                    setGenerateEnabled(null);
                  }
                }}
              />
            </Tooltip>
          </HStack>
        )}
      </HStack>
    </VStack>
  );
};

export default AiQueryButton;
