import { BlockMeili } from "../blocks.types";
import { API_URL } from "./auth";

export const getBlocksMeili = async (
  token: string | null,
  q?: string,
): Promise<BlockMeili[]> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const qs = new URLSearchParams();

  if (q) {
    qs.append("q", q);
  }

  const qsUrl = `?${qs.toString()}`;

  const resp = await fetch(API_URL + `/api/blocks-meili${qsUrl}`, {
    method: "GET",
    headers,
  });

  if (resp.status !== 200) {
    const json = await resp.json();

    throw new Error(
      json?.detail ||
        `Could not fetch matched blocks due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
