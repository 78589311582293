import { useMutation } from "react-query";
import { AppEventData, sendAppEvent } from "../api/send-app-event";
import { useLocation } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

export const useSendAppEvent = (opts?: any) => {
  const loc = useLocation();
  const { user } = useUser();

  return useMutation(
    async (d: AppEventData) => {
      let maybeIp = null;

      try {
        const resp = await fetch("https://api.ipify.org?format=json");
        const json = await resp.json();

        maybeIp = json.ip;
      } catch (_) {}

      await sendAppEvent({
        ...d,
        data: {
          ...(d.data || {}),
          path: loc.pathname,
          referrer: document.referrer,
          screen: window.screen.width,
          userAgent: navigator.userAgent,
          query: loc.search,
          queryMap: Object.fromEntries(new URLSearchParams(loc.search)),
          ip: maybeIp,
        },
        user_id: user?.id,
      });
    },
    {
      ...(opts || {}),
    },
  );
};
