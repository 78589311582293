import { API_URL } from "./auth";

export type ValidateQueryResult =
  | {
      valid: true;
      details: null;
      formatted: string;
    }
  | {
      valid: false;
      details: string;
      formatted: null;
    };

export const validateQuery = async ({
  token,
  query,
}: {
  token: string | null;
  query: string;
}): Promise<ValidateQueryResult> => {
  if (!query) {
    return {
      valid: false,
      details: "No query to validate",
      formatted: null,
    };
  }

  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const resp = await fetch(API_URL + `/api/validate-query`, {
    method: "POST",
    body: JSON.stringify({ query }),
    headers,
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not validate the given query due to an ${resp.status} HTTP status code error`,
    );
  }

  const data = await resp.json();

  return data;
};
