import * as React from "react";
import { useQuery } from "react-query";
import { getSheetFileDetails } from "./api/get-sheet-file-details";
import {
  Button,
  HStack,
  IconButton,
  Input,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useClerkToken } from "./providers/clerk-token";
import {
  CheckIcon,
  HamburgerIcon,
  LinkIcon,
  RepeatIcon,
  ViewIcon,
} from "@chakra-ui/icons";
import { useSyncSheetFile } from "./hooks/use-sync-sheet-file";
import { durationDelta, msToTime } from "./utils";
import { useSpreadsheetSheetPatch } from "./hooks/use-spreadsheet-sheet-patch";
import BlockModal from "./BlockModal";

type Props = {
  id: string;
  enabled: boolean;
};

const SheetDetails = ({ id, enabled }: Props) => {
  const { token } = useClerkToken();

  const [wsId, setWsId] = React.useState(0);
  const [previewOpen, setPreviewOpen] = React.useState(false);

  const href = `https://docs.google.com/spreadsheets/d/${id}/edit`;

  const { data, isLoading, isRefetching, error, refetch } = useQuery(
    ["SHEET_FILES", id],
    () => getSheetFileDetails(token, id, wsId),
    {
      enabled: !!token && enabled,
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
      retry: 2,
      retryDelay: 3000,
      retryOnMount: true,
    },
  );

  const [headerRange, setHeaderRange] = React.useState(
    data?.worksheet.header_range,
  );

  const {
    mutate: syncSheetFile,
    isLoading: isSyncing,
    isSuccess: isSynced,
  } = useSyncSheetFile();

  const {
    mutate: patchSheet,
    isLoading: isPatching,
    isSuccess: isPatched,
  } = useSpreadsheetSheetPatch();

  const loading = isLoading || isRefetching || isPatching;

  React.useEffect(() => {
    if (enabled) {
      refetch();
    }
  }, [enabled, wsId, refetch]);

  React.useEffect(() => {
    if (isSynced) {
      refetch();
    }
  }, [isSynced, isPatched, refetch]);

  React.useEffect(() => {
    if (data?.worksheet.header_range) {
      setHeaderRange(data.worksheet.header_range);
    }
  }, [data, setHeaderRange]);

  if (error && !syncSheetFile) {
    return (
      <SimpleGrid spacing={2}>
        <Text fontSize="x-small">
          Could not load sheet details, {(error as Error).toString()}
        </Text>
      </SimpleGrid>
    );
  }

  let blockId: string | undefined;

  if (data?.worksheet) {
    blockId = `${data.id}__${data.worksheet.id}__${data.user_id}`;
  }

  return (
    <SimpleGrid spacing={2}>
      <HStack spacing={1}>
        <Menu>
          <MenuButton
            isDisabled={!data?.worksheets?.length || isLoading}
            as={Button}
            rightIcon={<HamburgerIcon />}
            sx={{ flex: 1 }}
            textAlign="left"
            aria-label="Options"
            variant="ghost"
            size="xs"
            fontSize="12px"
          >
            <Text noOfLines={1}>Sheet #{data?.worksheet?.index}</Text>
          </MenuButton>
          <Portal>
            {!loading && (
              <MenuList zIndex={1000}>
                {data?.worksheets?.map(({ id, index, title }, ix) => (
                  <MenuItem
                    key={`ws_${id}_${index}_${ix}`}
                    isDisabled={loading}
                    onClick={() => setWsId(index)}
                  >
                    <Text noOfLines={1} fontSize="xs">
                      Sheet {index} - {title}
                    </Text>
                  </MenuItem>
                ))}
              </MenuList>
            )}
          </Portal>
        </Menu>
        <Tooltip label="Re-sync sheet">
          <IconButton
            onClick={() => syncSheetFile(id)}
            isLoading={isSyncing || isLoading}
            isDisabled={
              isSyncing ||
              (!!data &&
                !["finished", "failed"].includes(
                  data?.sync?.status || "started",
                ))
            }
            variant="link"
            color="cyan.800"
            size="xs"
            aria-label="Re-sync sheet"
            icon={<RepeatIcon />}
          />
        </Tooltip>
        {blockId && (
          <Tooltip label="Preview sheet data">
            <IconButton
              onClick={() => setPreviewOpen(true)}
              variant="link"
              color="purple.800"
              size="xs"
              aria-label="Preview sheet"
              icon={<ViewIcon />}
            />
          </Tooltip>
        )}
        <Tooltip label="Open sheet in new tab">
          <IconButton
            onClick={() => window.open(href + "#gid=" + data?.worksheet?.id)}
            variant="link"
            color="purple.800"
            size="xs"
            aria-label="Open Google Sheet"
            icon={<LinkIcon />}
          />
        </Tooltip>
        {blockId && (
          <BlockModal
            blockId={blockId}
            isOpen={previewOpen}
            onClose={() => setPreviewOpen(false)}
          />
        )}
      </HStack>
      {data && (
        <VStack ml={2} spacing={1} alignItems="left" overflowX="auto">
          <Text fontSize="xs">
            <b>{data.worksheet.title}</b>
            {data.sync && (
              <Text fontSize="xx-small">
                Sync {data?.sync.status}{" "}
                {data?.sync.finished_at
                  ? ` ${durationDelta(new Date(data?.sync.finished_at))}`
                  : ""}
                {data.sync.elapsed_ms
                  ? ` in ${msToTime(data.sync.elapsed_ms)}`
                  : ""}
              </Text>
            )}
          </Text>
          <HStack spacing={1}>
            <Text fontSize="x-small" minW="72px">
              Used header{" "}
            </Text>
            <Input
              size="xs"
              value={headerRange}
              onChange={(e) => setHeaderRange(e.target.value)}
            />
            <Tooltip label="Save header range">
              <IconButton
                onClick={() =>
                  patchSheet({
                    id: data.id,
                    sheetId: data.worksheet.id,
                    params: { header_range: headerRange },
                  })
                }
                variant="ghost"
                color="purple.800"
                size="xs"
                fontSize="12px"
                aria-label="Save header range"
                isLoading={isPatching}
                isDisabled={
                  headerRange === data.worksheet.header_range || isPatching
                }
                icon={<CheckIcon />}
              />
            </Tooltip>
          </HStack>
          {data?.worksheet.exact_row_count ? (
            <Text fontSize="xx-small">
              {data?.worksheet.exact_row_count} rows
            </Text>
          ) : (
            <Text fontSize="xx-small">~ {data?.worksheet.row_count} rows</Text>
          )}
          <Text fontSize="xs">
            <b>Columns</b>
          </Text>
          <List>
            {data?.worksheet.header_values?.map((h, ix) => (
              <ListItem key={`header_${ix}`} mb={1}>
                <Text fontSize="x-small">
                  <pre>{h}</pre>
                </Text>
              </ListItem>
            ))}
          </List>
        </VStack>
      )}
    </SimpleGrid>
  );
};

export default SheetDetails;
