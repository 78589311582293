import { useEffect, useState } from "react";
import { useClerkToken } from "../providers/clerk-token";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { API_URL } from "../api/auth";

export const useAi = (
  path: "explain" | "generate",
  onFinish: () => void,
  enabled: boolean,
  params?: any,
) => {
  const { token } = useClerkToken();

  const [ctrl, setCtrl] = useState<AbortController | null>(null);

  const [process, setProcess] = useState<{
    status: "pending" | "open" | "close" | "error" | "canceled";
    error?: string;
  }>({
    status: "pending",
  });

  const [data, setData] = useState("");

  const reset = () => {
    setData("");
    setProcess({ status: "pending" });
  };

  useEffect(() => {
    const internalCtrl = new AbortController();

    const subscribe = async () => {
      await fetchEventSource(`${API_URL}/api/ai`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/event-stream",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          type: path,
          ...params,
        }),
        onopen: async (_) => {
          setProcess({ status: "open" });
        },
        onmessage: (event) => {
          const data = JSON.parse(event.data);
          setData((prev) => prev + data.text);
        },
        onerror: (error: Error) => {
          setProcess({ status: "error", error: error.message });
          onFinish();
        },
        onclose: () => {
          setProcess({ status: "close" });
          onFinish();
        },
        signal: internalCtrl.signal,
      });
    };

    if (enabled) {
      setData("");
      setCtrl(internalCtrl);

      subscribe();
    }
  }, [enabled]);

  return {
    process,
    data,
    reset,
    cancel: () => {
      ctrl?.abort();

      setCtrl(null);
      setProcess({ status: "canceled" });

      onFinish();
    },
  };
};
