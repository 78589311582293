import { API_URL } from "./auth";
import { UserSavedQuery } from "./get-query";
import { QueryParams } from "./save-query";

export const updateQuery = async ({
  token,
  id,
  query,
}: {
  token: string | null;
  id: string;
  query: QueryParams;
}): Promise<UserSavedQuery> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const resp = await fetch(API_URL + `/api/queries/${id}`, {
    method: "PUT",
    headers,
    body: JSON.stringify(query),
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not update query due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
