import { API_URL } from "./auth";
import { UserSavedQuery } from "./get-query";

export const getQueries = async (
  token: string | null,
): Promise<UserSavedQuery[]> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const resp = await fetch(API_URL + "/api/queries", {
    method: "GET",
    headers,
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not fetch queries due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
