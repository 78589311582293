import {
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  SimpleGrid,
  Spinner,
  Switch,
  Tag,
  Text,
  Tooltip,
  VStack,
  useDimensions,
  useToast,
} from "@chakra-ui/react";
import BlocksList from "./BlocksList";
import React from "react";
import { InfoIcon, SettingsIcon, ViewIcon } from "@chakra-ui/icons";
import ReactFlow, {
  ReactFlowProvider,
  Controls,
  Background,
  Panel,
  Node,
  Edge,
  NodeProps,
  getNodesBounds,
  getViewportForBounds,
  applyNodeChanges,
  applyEdgeChanges,
  useReactFlow,
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  Handle,
  Position,
  isNode,
} from "reactflow";
import "reactflow/dist/style.css";
import { OperationCard, WorkflowDetailsModal } from "./Workflows";
import {
  Op,
  Workflow,
  WorkflowGraphEdgeData,
  WorkflowGraphNodeData,
} from "./blocks.types";
import BlockModal from "./BlockModal";
import { useDefaultWorkflow } from "./hooks/use-default-workflow";
import { useWorkflowPatch } from "./hooks/use-workflow-patch";
import { useWorkflowGraph } from "./hooks/use-workflow-graph";
import { useOp } from "./hooks/use-op";
import { useQueryClient } from "react-query";
import dagre from "@dagrejs/dagre";
import { useWorkflow } from "./hooks/use-workflow";

const NODE_WIDTH = 320;
const NODE_HEIGHT = 200;

/**
 * This function takes a dagre graph and a list of elements and returns the elements with updated positions
 * based on the layouting done by {@link dagre}.
 */
const getLayoutedElements = (
  dagreGraph: dagre.graphlib.Graph,
  elements: (Node<WorkflowGraphNodeData> | Edge<WorkflowGraphEdgeData>)[],
  direction = "TB",
): (Node<WorkflowGraphNodeData> | Edge<WorkflowGraphEdgeData>)[] => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el: any) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el: any) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);

      el.targetPosition = (isHorizontal ? "left" : "top") as any;
      el.sourcePosition = (isHorizontal ? "right" : "bottom") as any;

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      el.position = {
        x: nodeWithPosition.x - NODE_WIDTH / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - NODE_HEIGHT / 2,
      };
    }

    return el;
  });
};

type WorkflowFlowGraphWrapperProps = {
  wfId: string;
};

const WorkflowFlowGraphWrapper = ({ wfId }: WorkflowFlowGraphWrapperProps) => {
  const { data, isLoading } = useWorkflowGraph(wfId);

  if (isLoading) {
    return (
      <VStack m={10}>
        <Spinner />
      </VStack>
    );
  }

  if (!data) {
    return (
      <VStack m={10}>
        <Text fontSize="x-small">Could not fetch workflow graph</Text>
      </VStack>
    );
  }

  return (
    <ReactFlowProvider>
      <WorkflowFlowGraph
        wfId={data.id}
        initialNodes={data.data.nodes}
        initialEdges={data.data.edges}
      />
    </ReactFlowProvider>
  );
};

const OpTypeToTag = ({ opType }: { opType?: Op["type"] }) => {
  if (opType === "sql_saved_query") {
    return (
      <Tag size="sm" colorScheme="green">
        SQL
      </Tag>
    );
  } else if (opType === "email_notification") {
    return (
      <Tag size="sm" colorScheme="red">
        Email
      </Tag>
    );
  }

  return null;
};

const OperationEdge = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  selected,
  markerEnd,
  data,
}: EdgeProps<WorkflowGraphEdgeData>) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            cursor: "pointer",
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <VStack
            spacing={1}
            alignItems="center"
            background={selected ? "purple.200" : "gray.100"}
            p={3}
            borderRadius={4}
            border="0.5px solid"
          >
            <Text fontSize="xx-small" fontStyle="italic">
              Operation
            </Text>
            <Tooltip label="Edit operation">
              <HStack spacing={1}>
                <OpTypeToTag opType={data?.op_type as Op["type"] | undefined} />
                <Text>{data?.label}</Text>
              </HStack>
            </Tooltip>
          </VStack>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

const BlockNode = ({ id, data }: NodeProps<WorkflowGraphNodeData>) => {
  const [selected, setSelected] = React.useState(false);

  return (
    <Box
      bg={`${data.fill}.100`}
      p={3}
      border="1px solid"
      borderRadius={4}
      maxW={`${NODE_WIDTH}px`}
      sx={{
        display: "block",
      }}
    >
      <Handle type="target" position={Position.Top} isConnectable={false} />
      <VStack spacing={1} alignItems="center">
        <Text fontSize="xx-small" fontStyle="italic">
          Block
        </Text>
        <HStack alignItems="center" justifyContent="space-between">
          <Text color={`${data.fill}.800`} fontSize="xs">
            <b>{data.label}</b>
          </Text>
          <Tooltip label="Preview block">
            <IconButton
              aria-label="Preview block"
              icon={<ViewIcon />}
              onClick={() => setSelected(true)}
              variant="ghost"
              size="xs"
              color={`${data.fill}.800`}
              colorScheme="whiteAlpha"
            />
          </Tooltip>
        </HStack>
        {selected && (
          <BlockModal
            isOpen={selected}
            onClose={() => setSelected(false)}
            blockId={id}
          />
        )}
      </VStack>
      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={false}
      />
    </Box>
  );
};

type OperationUpdateProps = {
  id: string;
  onRemove: () => void;
  onClose: () => void;
};

const OperationUpdate = ({ id, onRemove, onClose }: OperationUpdateProps) => {
  const { data, isLoading, isRefetching, refetch } = useOp(id);

  React.useEffect(() => {
    refetch();
  }, [id, refetch]);

  if (isLoading || isRefetching) {
    return <Spinner size="xs" />;
  }

  if (!data) {
    return <Text fontSize="xs">Could not fetch operation</Text>;
  }

  return (
    <OperationCard operation={data} onRemove={onRemove} onClose={onClose} />
  );
};

type WorkflowFlowGraphProps = {
  wfId: string;
  initialNodes: Node<WorkflowGraphNodeData>[];
  initialEdges: Edge<WorkflowGraphEdgeData>[];
};

const nodeTypes = {
  block: BlockNode,
};

const edgeTypes = {
  operation: OperationEdge,
};

const WorkflowFlowGraph = ({
  wfId,
  initialNodes,
  initialEdges,
}: WorkflowFlowGraphProps) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [selectedEdge, setSelectedEdge] = React.useState<string | null>(null);

  const { getNodes, getEdges, setViewport, fitView } = useReactFlow();

  const elementRef = React.useRef();
  const dimensions = useDimensions(elementRef as any, true);

  const [nodes, setNodes] = React.useState(initialNodes);
  const [edges, setEdges] = React.useState(initialEdges);

  const onNodesChange = React.useCallback(
    (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [nodes],
  );

  const onEdgesChange = React.useCallback(
    (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [edges],
  );

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const hasSidebar = selectedEdge || createOpen;

  React.useEffect(() => {
    const layoutedElements = getLayoutedElements(dagreGraph, [
      ...initialNodes,
      ...initialEdges,
    ]);

    // For the nodes, we add the new ones and update the existing ones
    // as we want to keep the `position`s
    setNodes(layoutedElements.filter(isNode));

    setEdges(
      layoutedElements.filter(
        (el) => !isNode(el),
      ) as Edge<WorkflowGraphEdgeData>[],
    );
  }, [initialNodes, initialEdges, getNodes, setNodes, setEdges]);

  React.useEffect(() => {
    const nodes = getNodes();
    const bound = getNodesBounds(nodes);

    const width = dimensions?.borderBox.width || 1000;
    const height = dimensions?.borderBox.height || 500;

    const vp = getViewportForBounds(
      bound,
      hasSidebar ? width / 2 : width,
      height,
      0.75,
      2,
    );

    setViewport(vp, { duration: 500 });
  }, [
    setViewport,
    getNodes,
    getNodesBounds,
    getViewportForBounds,
    dimensions,
    hasSidebar,
  ]);

  React.useEffect(() => {
    const edges = getEdges();

    // We need to do this as there's no way
    // to deselect an edge in react-flow without setting
    // the whole edges array
    setEdges(
      edges.map((e) => ({ ...e, selected: e.data?.op_id === selectedEdge })),
    );
  }, [selectedEdge, setEdges, getEdges]);

  return (
    <SimpleGrid
      columns={hasSidebar ? 2 : 1}
      spacing={4}
      ref={elementRef as any}
      height="80vh"
    >
      <Box
        height={dimensions?.borderBox.height}
        flex={1}
        sx={{ border: "1px solid #e5e7eb" }}
      >
        <Box width="100%" height="100%">
          <ReactFlow
            nodes={nodes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            edges={edges}
            onPaneClick={() => setSelectedEdge(null)}
            onEdgeClick={(_, d) => setSelectedEdge(d?.data?.op_id || null)}
            snapToGrid
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onLoad={() => fitView()}
          >
            <Panel position="top-right">
              <Button
                size="xs"
                colorScheme="purple"
                onClick={() => {
                  setSelectedEdge(null);
                  setCreateOpen(true);
                }}
              >
                Add operation
              </Button>
            </Panel>
            <Background />
            <Controls />
          </ReactFlow>
        </Box>
      </Box>
      {createOpen && (
        <OperationCard
          operation={{
            workflow_id: wfId,
          }}
          onRemove={() => setCreateOpen(false)}
          onClose={() => setCreateOpen(false)}
        />
      )}
      {selectedEdge && (
        <OperationUpdate
          id={selectedEdge}
          onRemove={() => setSelectedEdge(null)}
          onClose={() => setSelectedEdge(null)}
        />
      )}
    </SimpleGrid>
  );
};

type WorkflowEnabledSwitchProps = {
  wfId: string;
};

const WorkflowEnabledSwitch = ({ wfId }: WorkflowEnabledSwitchProps) => {
  const toast = useToast();
  const qc = useQueryClient();

  const { data, isLoading } = useWorkflow(wfId);

  const { mutate, isLoading: isPatching } = useWorkflowPatch({
    onSuccess: (resp: Workflow) => {
      toast({
        title: `${resp.name} ${resp.enabled ? "enabled" : "disabled"}!`,
        status: "info",
        duration: 1_500,
      });

      qc.invalidateQueries(["WORKFLOWS"]);
    },
  });

  if (isLoading) {
    return <Spinner size="xs" />;
  }

  if (!data) {
    return null;
  }

  return (
    <Tooltip
      label={data?.enabled ? "Disable workflow" : "Enable workflow"}
      placement="bottom"
    >
      <Box>
        <Switch
          size="md"
          disabled={isLoading || isPatching}
          colorScheme="purple"
          isReadOnly={isPatching}
          isChecked={data.enabled}
          onChange={({ target }) =>
            mutate({
              id: data.id,
              params: {
                enabled: target.checked,
              },
            })
          }
        />
      </Box>
    </Tooltip>
  );
};

const Workflows = () => {
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const { data: wf, isLoading } = useDefaultWorkflow();

  if (isLoading) {
    return (
      <Container maxW="container.2xl" p={2}>
        <Spinner />
      </Container>
    );
  }

  if (!wf) {
    return (
      <Container maxW="container.2xl" p={2}>
        <Text fontSize="sm">Could not fetch workflow</Text>
      </Container>
    );
  }

  return (
    <Container maxW="container.2xl" p={2}>
      <Grid templateColumns="repeat(12, 1fr)" gap={6} m={2}>
        <GridItem colSpan={expanded ? 3 : 1} rowSpan={8}>
          <BlocksList expanded={expanded} onExpandChange={setExpanded} />
        </GridItem>
        <GridItem colSpan={expanded ? 9 : 11}>
          <HStack justifyContent="space-between">
            <HStack spacing={2} alignItems="center">
              <Heading size="md">Default workflow</Heading>
              <Tooltip
                label={
                  "A workflow is a sequence of operations that are executed in a herarchical order. Adding more workflows will be available soon, stay tuned!"
                }
              >
                <InfoIcon boxSize={3} />
              </Tooltip>
            </HStack>
            <HStack spacing={2} alignItems="center">
              <WorkflowEnabledSwitch wfId={wf.id} />
              <Tooltip label="See and configure your workflow!">
                <Button
                  size="xs"
                  variant="outline"
                  colorScheme="purple"
                  onClick={() => setDetailOpen(true)}
                >
                  <HStack spacing={2} alignItems="center">
                    <SettingsIcon />
                    <Text fontSize="xs">Runs</Text>
                  </HStack>
                </Button>
              </Tooltip>
            </HStack>
          </HStack>
          <WorkflowDetailsModal
            wfId={wf.id}
            showGraph={false}
            isOpen={detailOpen}
            onClose={() => setDetailOpen(false)}
          />
        </GridItem>
        <GridItem colSpan={expanded ? 9 : 11}>
          <WorkflowFlowGraphWrapper wfId={wf.id} />
        </GridItem>
      </Grid>
    </Container>
  );
};

export default Workflows;
