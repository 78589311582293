import { TabularData } from "../types";
import { API_URL } from "./auth";

export type SheetQueryResult = {
  data: TabularData;
  cache_hit: boolean;
  elapsed: number;
  ranAt: Date;
};

export const previewSheetQuery = async ({
  token,
  query,
}: {
  token: string | null;
  query: string;
}): Promise<SheetQueryResult> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const resp = await fetch(API_URL + `/api/sheet-files-query`, {
    method: "POST",
    body: JSON.stringify({ query }),
    headers,
  });

  if (resp.status !== 200) {
    const json = await resp.json();
    throw new Error(
      json.detail ||
        "Could not run query due to unknown issue, if it keeps failing, please contact support.",
    );
  }

  const data = await resp.json();

  return {
    ...data,
    elapsed: data.elapsed || Number(resp.headers.get("X-Process-Time") || 0),
    ranAt: new Date(),
  };
};
