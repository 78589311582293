import { API_URL } from "./auth";

export const syncSheets = async ({ token }: { token: string | null }) => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const resp = await fetch(API_URL + `/api/sheet-files-sync`, {
    method: "POST",
    headers,
  });

  if (resp.status !== 204) {
    throw new Error(
      `Could not request sheets sync due to ${resp.status} HTTP error code`,
    );
  }
};
