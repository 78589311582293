import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { getDefaultWorkflow } from "../api/get-default-workflow";

export const useDefaultWorkflow = (opts?: any) => {
  const { token } = useClerkToken();

  return useQuery(["WORKFLOWS", "DEFAULT"], () => getDefaultWorkflow(token), {
    ...(opts || {}),
    // Default
    enabled: !!token,
  });
};
