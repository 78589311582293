import { OpUpsertParams, OpWithData } from "../blocks.types";
import { API_URL } from "./auth";

export const upsertOp = async ({
  token,
  data,
}: {
  token: string | null;
  data: OpUpsertParams;
}): Promise<OpWithData> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const resp = await fetch(API_URL + `/api/b/ops-upsert`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });

  const json = await resp.json();

  if (resp.status !== 200) {
    if (json.detail) {
      throw new Error(json.detail);
    }

    throw new Error(
      `Could not upsert operation due to ${resp.status} HTTP error code`,
    );
  }

  return json;
};
