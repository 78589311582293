import { useMutation, useQueryClient } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { deleteOp } from "../api/delete-op";

export const useDeleteOp = (opts?: any) => {
  const { token } = useClerkToken();
  const qc = useQueryClient();

  return useMutation((id: string) => deleteOp({ token, id }), {
    ...(opts || {}),
    onSuccess: () => {
      qc.invalidateQueries(["WORKFLOWS"]);
      qc.invalidateQueries(["OP_PREVIEW"]);

      if (opts?.onSuccess) {
        opts.onSuccess();
      }
    },
  });
};
