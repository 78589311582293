import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { getBlocks } from "../api/get-blocks";

type Props = {
  ids?: string[];
  query?: string;
  limit?: number;
  offset?: number;
};

export const useBlocks = (props: Props, opts?: any) => {
  const { token } = useClerkToken();

  return useQuery(
    ["BLOCKS", props],
    () => getBlocks(token, props.ids, props.query, props.limit, props.offset),
    {
      ...(opts || {}),
      // Default
      enabled: !!token,
    },
  );
};
