import { API_URL } from "./auth";

export const syncSheetFile = async ({
  token,
  id,
}: {
  token: string | null;
  id: string;
}) => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const resp = await fetch(API_URL + `/api/sheet-files/${id}/sync`, {
    method: "POST",
    headers,
  });

  if (resp.status !== 204) {
    throw new Error(
      `Could not request sheet sync due to ${resp.status} HTTP error code`,
    );
  }
};
