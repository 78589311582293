import { API_URL } from "./auth";
import { Schedule } from "./get-schedules";

export type ScheduleParams = {
  query_id: string;
  schedule: string;
  description?: string;
};

export const createSchedule = async ({
  token,
  data,
}: {
  token: string | null;
  data: ScheduleParams;
}): Promise<Schedule> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const resp = await fetch(API_URL + `/api/jobs`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });

  // XD
  if (resp.status > 201) {
    let error = `Could not schedule query due to ${resp.status} HTTP error code`;

    if (resp.status < 500 && resp.status >= 400) {
      const json = await resp.json();
      error = json["detail"];
    }

    throw new Error(error);
  }

  return (await resp.json())["data"];
};
