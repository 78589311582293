import { Box, Link, Spinner, Text, VStack } from "@chakra-ui/react";
import { useBlockData } from "./hooks/use-block-data";
import { BlockWithData } from "./blocks.types";
import DataTableContent from "./DataTableContent";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { msToTime } from "./utils";

type Props = {
  block: BlockWithData;
};

const BlockDetails = ({ block }: Props) => {
  const { data, isLoading } = useBlockData(block.id);

  if (isLoading) {
    return (
      <VStack spacing={2}>
        <Spinner />
      </VStack>
    );
  }

  if (!data) {
    return (
      <VStack spacing={2}>
        <Text fontSize="lg">{block.name}</Text>
        <Text fontSize="xs">No data available</Text>
      </VStack>
    );
  }

  let header = null;
  let footer = null;

  if (block.source_type === "google_sheet") {
    const href = `https://docs.google.com/spreadsheets/d/${block.sheet.spreadsheet_id}/edit`;

    header = (
      <Text fontSize="xs">
        Preview of the block data coming from a{" "}
        <Link color="purple.800" href={href} isExternal>
          Google Sheet
          <ExternalLinkIcon mx="2px" />
        </Link>{" "}
        last updated at{" "}
        {new Date(
          block.sheet.updated_at || block.sheet.created_at,
        ).toLocaleString()}{" "}
        and processed in {msToTime(data.process_time)}.
      </Text>
    );

    footer = (
      <Text fontSize="xs">
        The block has a total of {block.sheet.exact_row_count} rows and{" "}
        {block.sheet.column_count} columns.
      </Text>
    );
  } else if (block.source_type === "s3_location") {
    header = (
      <Text fontSize="xs">
        Preview of the block data coming from an operation output, processed in{" "}
        {msToTime(data.process_time)}.
      </Text>
    );

    footer = (
      <Text fontSize="xs">The block has a total of {data.count} rows.</Text>
    );
  }

  return (
    <VStack spacing={2}>
      <Text fontSize="lg">{block.name}</Text>
      {header}
      <Box overflowY="auto" maxW="3xl">
        <DataTableContent data={data.data} showCaption={false} />
      </Box>
      {footer}
    </VStack>
  );
};

export default BlockDetails;
