import { useMutation, useQueryClient } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { upsertOp } from "../api/upsert-op";
import { OpUpsertParams } from "../blocks.types";

export const useUpsertOp = (opts?: any) => {
  const { token } = useClerkToken();
  const qc = useQueryClient();

  return useMutation((data: OpUpsertParams) => upsertOp({ token, data }), {
    ...(opts || {}),
    onSuccess: () => {
      qc.invalidateQueries(["WORKFLOWS"]);
      qc.invalidateQueries(["OP_PREVIEW"]);

      if (opts?.onSuccess) {
        opts.onSuccess();
      }
    },
  });
};
