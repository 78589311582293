import { API_URL } from "./auth";

export type AppEventData = {
  ty:
    | "landing_visit"
    | "landing_click"
    | "landing_scroll"
    | "landing_loom_play"
    | "landing_playground"
    | "register_intent"
    | "register"
    | "login_intent"
    | "login"
    | "app_visit"
    | "app_click";
  data?: any;
  user_id?: string;
};

export const sendAppEvent = async (data: AppEventData) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  const resp = await fetch(API_URL + `/__e`, {
    method: "POST",
    body: JSON.stringify(data),
    headers,
  });

  if (resp.status !== 200) {
    console.error(`Failed to send app event: ${resp.status}`);
  }
};
