import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { getSheetFiles } from "../api/get-sheet-files";

export type Props = {
  q?: string;
  limit?: number;
  offset?: number;
};

export const useSheetFiles = (props: Props, opts?: any) => {
  const { token } = useClerkToken();

  return useQuery(
    ["SHEET_FILES", props],
    () => getSheetFiles(token, props.q, props.limit, props.offset),
    {
      ...(opts || {}),
      // Default
      enabled: !!token,
      refetchOnWindowFocus: false,
      retry: true,
    },
  );
};
