import { useMutation, useQueryClient } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import {
  PatchSpreadsheetSheetInput,
  patchSpreadsheetSheet,
} from "../api/patch-spreadsheet-sheet";

export const useSpreadsheetSheetPatch = (opts?: any) => {
  const { token } = useClerkToken();
  const queryClient = useQueryClient();

  return useMutation(
    (p: PatchSpreadsheetSheetInput) => patchSpreadsheetSheet({ token, ...p }),
    {
      ...(opts || {}),
      onSuccess: (data, vars, ctx) => {
        queryClient.invalidateQueries(["SHEET_FILES"]);

        if (opts?.onSuccess) {
          opts.onSuccess(data, vars, ctx);
        }
      },
    },
  );
};
