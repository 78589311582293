import {
  EditorView,
  keymap,
  Decoration,
  ViewPlugin,
  WidgetType,
  DecorationSet,
  ViewUpdate,
  MatchDecorator,
} from "@codemirror/view";
import CodeMirror from "@uiw/react-codemirror";
import { defaultKeymap, indentWithTab, history } from "@codemirror/commands";
import { githubLight as theme } from "@uiw/codemirror-theme-github";
import {
  CompletionContext,
  autocompletion,
  completionKeymap,
} from "@codemirror/autocomplete";
import {
  sql,
  keywordCompletionSource,
  StandardSQL,
} from "@codemirror/lang-sql";
import { SheetFile } from "./api/get-sheet-files";
import { useClerkToken } from "./providers/clerk-token";
import { useSheetFiles } from "./hooks/use-sheet-files";
import { useBlocks } from "./hooks/use-blocks";
import { Block } from "./blocks.types";
import React from "react";
import { Box } from "@chakra-ui/react";
import BlockModal from "./BlockModal";
import { useUser } from "./hooks/use-user";
import SheetModal from "./SheetModal";
import { getSpreadsheetSheets } from "./api/get-spreadsheet-sheets";
import { getBlocksMeili } from "./api/get-blocks-meili";

type Props = {
  value: string;
  onChange: (q: string) => void;
  normalMode?: boolean;
};

export const SheetSQLCodeMirrorEditor = ({
  value,
  onChange,
  normalMode,
}: Props) => {
  const { data: freshFiles, isSuccess } = useSheetFiles({
    limit: 1000,
  });

  const { data: freshBlocks, isSuccess: isBlocksSuccess } = useBlocks({
    limit: 10000,
  });

  const [blockClicked, setBlockClicked] = React.useState<string | null>(null);
  const [sheetClicked, setSheetClicked] = React.useState<{
    id: string;
    ix: number;
  } | null>(null);

  const { token } = useClerkToken();
  const { data: user } = useUser();

  if (!token || !user) {
    return;
  }

  if (!isSuccess || !isBlocksSuccess) {
    return;
  }

  const freshFilesGrouped = () => {
    const fs = freshFiles.data || [];
    const grouped = new Map<string, SheetFile>();

    for (const f of fs) {
      grouped.set(f.id, f);
    }

    return grouped;
  };

  const freshBlocksGrouped = () => {
    const fs = freshBlocks.data || [];
    const grouped = new Map<string, Block>();

    for (const f of fs) {
      grouped.set(f.id, f);
    }

    return grouped;
  };

  class SheetNameWidget extends WidgetType {
    constructor(
      readonly id: string,
      readonly ix?: number,
    ) {
      super();
    }

    toString() {
      return `sh_id<${this.id},${this.ix || 0}>`;
    }

    eq(other: SheetNameWidget) {
      return other.id == this.id && other.ix === this.ix;
    }

    toDOM() {
      let tryEl = document.createElement("span");

      tryEl.style.border = "1px solid gray";
      tryEl.style.borderRadius = "4px";

      // XD
      tryEl.style.background = "#E9D8FD";
      tryEl.style.color = "black";
      tryEl.style.padding = "1px";

      const textEl = document.createElement("a");

      textEl.innerText = this.toString();

      // textEl.href = `https://docs.google.com/spreadsheets/d/${this.id}#gid=${this.ix || 0}`;
      // textEl.target = "_blank";
      textEl.style.cursor = "pointer";
      textEl.onclick = () => {
        setSheetClicked({
          id: this.id,
          ix: this.ix || 0,
        });
      };

      const groups = freshFilesGrouped();
      const match = groups.get(this.id);

      if (!match) {
        tryEl.appendChild(textEl);
        return tryEl;
      }

      let text = match.title;
      let ogText = text;

      if (text.length > 24) {
        text = text.slice(0, 24) + "...";
      }

      if (this.ix !== undefined) {
        text += ` (#${this.ix})`;
        ogText += ` (Sheet #${this.ix}) 🔗`;
      } else {
        ogText += ` (Sheet #0) 🔗`;
      }

      textEl.innerText = text;

      textEl.onmouseover = () => {
        textEl.innerText = ogText;
      };

      textEl.onmouseleave = () => {
        textEl.innerText = text;
      };

      tryEl.appendChild(textEl);

      return tryEl;
    }

    ignoreEvent() {
      return false;
    }
  }

  class BlockNameWidget extends WidgetType {
    constructor(readonly blockId: string) {
      super();
    }

    toString() {
      return `block_s3<${this.blockId}>`;
    }

    eq(other: BlockNameWidget) {
      return other.blockId == this.blockId;
    }

    toDOM() {
      let tryEl = document.createElement("span");

      tryEl.style.border = "1px solid gray";
      tryEl.style.borderRadius = "4px";

      // XD
      tryEl.style.background = "#FEEBC8";
      tryEl.style.color = "black";
      tryEl.style.padding = "1px";

      const textEl = document.createElement("a");

      textEl.innerText = this.toString();

      const groups = freshBlocksGrouped();
      const match = groups.get(this.blockId);

      if (!match) {
        tryEl.appendChild(textEl);
        return tryEl;
      }

      let text = match.name;
      let ogText = text;

      if (text.length > 24) {
        text = text.slice(0, 24) + "...";
      }

      textEl.innerText = text;

      textEl.onmouseover = () => {
        textEl.innerText = ogText;
      };

      textEl.onmouseleave = () => {
        textEl.innerText = text;
      };

      tryEl.appendChild(textEl);

      return tryEl;
    }

    ignoreEvent() {
      return false;
    }
  }

  const shIdMatcher = new MatchDecorator({
    regexp: /sh_id<(.[^<>]+)>/g,
    decoration: (match) => {
      const m = match[1];
      const spl = m.split(",");

      return Decoration.replace({
        widget: new SheetNameWidget(spl[0], spl?.[1] ? parseInt(spl[1]) : 0),
      });
    },
  });

  const blockMatcher = new MatchDecorator({
    regexp: /block_s3<(.[^<>]+)>/g,
    decoration: (match) => {
      return Decoration.replace({
        widget: new BlockNameWidget(match[1]),
      });
    },
  });

  const getPlaceholder = (matcher: MatchDecorator) => {
    return ViewPlugin.fromClass(
      class {
        placeholders: DecorationSet;
        constructor(view: EditorView) {
          this.placeholders = matcher.createDeco(view);
        }
        update(update: ViewUpdate) {
          this.placeholders = matcher.updateDeco(update, this.placeholders);
        }
      },
      {
        decorations: (instance) => instance.placeholders,
        provide: (plugin) =>
          EditorView.atomicRanges.of((view) => {
            return view.plugin(plugin)?.placeholders || Decoration.none;
          }),
      },
    );
  };

  const shouldSkipWork = (
    word: {
      from: number;
      to: number;
      text: string;
    } | null,
    context: CompletionContext,
    len = 3,
  ) => {
    return (
      !word ||
      (word.from == word.to && !context.explicit) ||
      word.text.length < len
    );
  };

  const sheetCompletions = async (context: CompletionContext) => {
    let word = context.matchBefore(/\w*/);

    if (!word || shouldSkipWork(word, context)) {
      return null;
    }

    let wshWord = context.matchBefore(/sh_id<(.[^<>]+)>\.\w*/);

    if (wshWord || !shouldSkipWork(wshWord, context)) {
      return null;
    }

    let blockWord = context.matchBefore(/block_s3<(.[^<>]+)>\.\w*/);

    if (blockWord || !shouldSkipWork(blockWord, context)) {
      return null;
    }

    try {
      const data = await getBlocksMeili(token, word.text);

      const options = [];

      for (const block of data) {
        if (block.type === "google_sheet") {
          options.push({
            label: block.title!,
            displayLabel: `Use sheet: ${block.title!}`,
            type: "variable",
            apply: `sh_id<${block.spreadsheet_id!},${block.sheet_ix || 0}>`,
          });
        } else if (block.type === "s3_location") {
          options.push({
            label: block.title,
            displayLabel: `Use block: ${block.title}`,
            type: "variable",
            apply: `block_s3<${block.id}>`,
          });
        }
      }

      return {
        from: word.from,
        options,
      };
    } catch (_) {
      return null;
    }
  };

  const workSheetCompletions = async (context: CompletionContext) => {
    let word = context.matchBefore(/sh_id<(.[^<>]+)>\.\w*/);

    if (!word || shouldSkipWork(word, context, 2)) {
      return null;
    }

    // TODO(taras) Maybe do RegEx? :/
    let id = word.text.split("sh_id<")?.[1]?.split(">")?.[0];

    if (!id) {
      return null;
    }

    try {
      const data = await getSpreadsheetSheets(token, id);

      const options = data.map((wsh) => ({
        // For some reason, the type definition is wrong
        label: (word as any).text as string,
        displayLabel: `Use worksheet #${wsh.index} - ${wsh.title}`,
        type: "variable",
        apply: `sh_id<${id},${wsh.index}>`,
      }));

      return {
        from: word.from,
        options,
      };
    } catch (_) {
      return null;
    }
  };

  let opts: any = {
    theme,
    extensions: [
      keymap.of([...defaultKeymap, ...completionKeymap, indentWithTab]),
      history(),
      autocompletion({
        override: [
          keywordCompletionSource(StandardSQL),
          sheetCompletions,
          workSheetCompletions,
        ],
      }),
      autocompletion(),
      sql(),
      getPlaceholder(shIdMatcher),
      getPlaceholder(blockMatcher),
    ],
    basicSetup: {
      foldGutter: true,
      dropCursor: true,
      allowMultipleSelections: false,
      indentOnInput: true,
    },
  };

  if (normalMode) {
    opts = {
      extensions: [
        keymap.of([...defaultKeymap, ...completionKeymap, indentWithTab]),
        history(),
        autocompletion({
          override: [sheetCompletions, workSheetCompletions],
        }),
        getPlaceholder(shIdMatcher),
        getPlaceholder(blockMatcher),
      ],
      basicSetup: {
        foldGutter: false,
        dropCursor: true,
        allowMultipleSelections: false,
        indentOnInput: true,
        lineNumbers: false,
      },
    };
  }

  return (
    <Box>
      {blockClicked !== null && (
        <BlockModal
          isOpen={blockClicked !== null}
          onClose={() => setBlockClicked(null)}
          blockId={blockClicked}
        />
      )}
      {sheetClicked !== null && (
        <SheetModal
          isOpen={sheetClicked !== null}
          onClose={() => setSheetClicked(null)}
          spreadsheetId={sheetClicked.id}
          sheetIx={sheetClicked.ix}
        />
      )}
      <CodeMirror
        value={value}
        maxHeight="400px"
        onChange={(value) => onChange(value)}
        {...opts}
      />
    </Box>
  );
};
