import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
  Text,
} from "@chakra-ui/react";
import { TabularData } from "./types";
import { useMemo, useState } from "react";
import { Table as LatTable } from "@latitude-data/react";

type Props = {
  data: TabularData;
  caption?: React.ReactNode;
  defaultRows?: number;
  showCaption?: boolean;
  maxH?: string;
};

class LataData {
  data: TabularData;

  rowCount: number;
  fields: any[];
  rows: unknown[][];

  constructor(data: TabularData) {
    this.data = data;

    this.rowCount = data.data.length;
    this.fields = data.columns.map((col) => ({ name: col, type: "unknown" }));
    this.rows = data.data;
  }

  serialize() {
    return this.data;
  }

  toJSON() {
    return JSON.stringify(this.data);
  }

  toCSV() {
    return this.data.data.map((row) => row.join(",")).join("\n");
  }

  toArray() {
    return this.data.data.map((row) => {
      const obj: Record<string, any> = {};

      this.data.columns.forEach((col, jx) => {
        obj[col] = row[jx];
      });

      return obj;
    });
  }
}

const DataTableContent = ({
  data,
  caption,
  showCaption,
  defaultRows,
  maxH,
}: Props) => {
  const rowsPage = defaultRows || 100;
  const [loaded, setLoaded] = useState(Math.min(data.data.length, rowsPage));

  const loadedData = useMemo(() => data.data.slice(0, loaded), [data, loaded]);
  const isFullyLoaded = loadedData.length === data.data.length;

  const latData = new LataData(data);

  return (
    <VStack>
      {false && <LatTable data={latData as any} isLoading={false} />}
      {showCaption && (
        <Text fontSize="xs" color="gray.700">
          {caption ? `${caption} / ` : ""} Showing {loadedData.length} of{" "}
          {data.data.length} rows
        </Text>
      )}
      <TableContainer overflowY="unset" maxH={maxH || "650px"}>
        <Table variant="simple" size="sm">
          <Thead
            position="sticky"
            top={0}
            zIndex="docked"
            background="purple.100"
          >
            <Tr>
              <Th position="sticky" left={0} background="purple.100">
                #
              </Th>
              {data.columns.map((col) => (
                <Th key={col} textTransform="none">
                  <code>{col}</code>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {loadedData.map((d, ix) => (
              <Tr key={`row_${ix}`}>
                <Td position="sticky" left={0} background="purple.100">
                  <code>{ix + 1}</code>
                </Td>
                {d.map((dd, jx) => (
                  <Td key={`row_${ix}_col_${jx}`}>
                    {dd ? dd.toString() : "-"}
                  </Td>
                ))}
              </Tr>
            ))}
            {!isFullyLoaded && (
              <Tr>
                <Td
                  colSpan={Math.min(data.columns.length + 1, 2)}
                  position="sticky"
                  left={0}
                >
                  <Text
                    color="purple.500"
                    cursor="pointer"
                    onClick={() => setLoaded((prev) => prev + rowsPage)}
                  >
                    Load {rowsPage} more rows...
                  </Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};

export default DataTableContent;
