import { BlockTabularData } from "../blocks.types";
import { API_URL } from "./auth";

export const getBlockData = async (
  token: string | null,
  id: string,
): Promise<BlockTabularData> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const resp = await fetch(API_URL + `/api/b/blocks/${id}/data`, {
    method: "GET",
    headers,
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not fetch block data due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
