import { useMutation, useQueryClient } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { WorkflowPatchParams } from "../blocks.types";
import { patchWorkflow } from "../api/patch-workflow";

export const useWorkflowPatch = (opts?: any) => {
  const { token } = useClerkToken();
  const queryClient = useQueryClient();

  return useMutation(
    (p: { id: string; params: WorkflowPatchParams }) =>
      patchWorkflow({ token, ...p }),
    {
      ...(opts || {}),
      onSuccess: (data, vars, ctx) => {
        queryClient.invalidateQueries(["WORKFLOWS"]);

        if (opts?.onSuccess) {
          opts.onSuccess(data, vars, ctx);
        }
      },
    },
  );
};
