import { Block } from "../blocks.types";
import { API_URL } from "./auth";

export const getBlocks = async (
  token: string | null,
  ids?: string[],
  query?: string,
  limit?: number,
  offset?: number,
): Promise<{
  data: Block[];
  total: number;
}> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const q = new URLSearchParams();

  if (ids?.length) {
    for (const id of ids) {
      q.append("ids", id);
    }
  }

  if (query) {
    q.append("q", query);
  }

  if (limit) {
    q.append("limit", limit.toString());
  } else {
    q.append("limit", "1000");
  }

  if (offset) {
    q.append("offset", offset.toString());
  }

  const qs = `?${q.toString()}`;

  const resp = await fetch(API_URL + `/api/b/blocks${qs}`, {
    method: "GET",
    headers,
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not fetch blocks due to ${resp.status} HTTP error code`,
    );
  }

  const data = await resp.json();

  return {
    data,
    total: parseInt(resp.headers.get("X-Total-Count") || data.length, 10),
  };
};
