import { HStack, Tag, Text, Tooltip } from "@chakra-ui/react";
import { Block } from "./blocks.types";

type BlockTagProps = {
  block: Block;
  onClick?: () => void;
};

const sourceToImg = (source: Block["source_type"]) => {
  switch (source) {
    case "google_sheet":
      return "/sh-logo.png";
    case "s3_location":
      return "/tabular.png";
    default:
      return "/tabular.png";
  }
};

const BlockTag = ({ block, onClick }: BlockTagProps) => {
  let colorScheme = "gray";
  let tooltipText = "Block info";

  if (block.source_type === "google_sheet") {
    colorScheme = "blue";
    tooltipText = `The source data of this block comes from a Google Sheet: ${block.name}, click to preview`;
  } else if (block.source_type === "s3_location") {
    colorScheme = "orange";
    tooltipText =
      "The source data of this block is the output of an operation, click to preview";
  }

  return (
    <Tooltip label={tooltipText}>
      <Tag
        size="md"
        colorScheme={colorScheme}
        cursor="pointer"
        onClick={onClick}
      >
        <HStack spacing={2} alignItems="center" p={2}>
          <img
            src={sourceToImg(block.source_type)}
            alt="source"
            width="12"
            height="12"
          />
          <Text fontSize="xs" noOfLines={2}>
            {block.name}
          </Text>
        </HStack>
      </Tag>
    </Tooltip>
  );
};

export default BlockTag;
