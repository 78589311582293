import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { getWorkflowRunById } from "../api/get-workflow-run-by-id";

export const useWorkflowRunById = (wfId: string, runId: number, opts?: any) => {
  const { token } = useClerkToken();

  return useQuery(
    ["WORKFLOWS", wfId, "RUNS", runId],
    () => getWorkflowRunById(token, wfId, runId),
    {
      ...(opts || {}),
      // Default
      enabled: !!token,
    },
  );
};
