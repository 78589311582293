import React from "react";
import { useAuth } from "@clerk/clerk-react";

const TEMPLATE = "Basic";

interface ClerkTokenContextType {
  token: string | null;
}

const ClerkContext = React.createContext<ClerkTokenContextType>(null!);

export function ClerkTokenProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [token, setToken] = React.useState<string | null>(null);

  const { getToken } = useAuth();

  React.useEffect(() => {
    const _getToken = async () => {
      setToken(await getToken({ template: TEMPLATE }));
    };

    _getToken();

    // Refresh token every minute
    const interval = setInterval(_getToken, 1_000 * 60);

    return () => clearInterval(interval);
  }, [setToken, getToken]);

  return (
    <ClerkContext.Provider value={{ token }}>{children}</ClerkContext.Provider>
  );
}

export function useClerkToken() {
  return React.useContext(ClerkContext);
}
