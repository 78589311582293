import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { getBlock } from "../api/get-block";

export const useBlock = (id: string, opts?: any) => {
  const { token } = useClerkToken();

  return useQuery(["BLOCK", id], () => getBlock(token, id), {
    ...(opts || {}),
    // Default
    enabled: !!token,
  });
};
