import {
  Text,
  Box,
  VStack,
  Heading,
  useToast,
  Spinner,
  Select,
  Input,
  List,
  ListItem,
  Tooltip,
  HStack,
  IconButton,
  Spacer,
} from "@chakra-ui/react";
import { useBlocks } from "./hooks/use-blocks";
import { fuzzyFilter } from "fuzzbunny";
import React from "react";
import { Block } from "./blocks.types";
import BlockModal from "./BlockModal";
import BlockTag from "./BlockTag";
import { ChevronLeftIcon, ChevronRightIcon, InfoIcon } from "@chakra-ui/icons";

type BlockListProps = {
  expanded?: boolean;
  onExpandChange?: (expanded: boolean) => void;
};

const BlocksList = ({ expanded = true, onExpandChange }: BlockListProps) => {
  const [filters, setFilters] = React.useState<{
    type: string | null;
    search: string | null;
  }>({ type: null, search: null });
  const [selectedBlock, setSelectedBlock] = React.useState<Block | null>(null);
  const [isExpanded, setIsExpanded] = React.useState(expanded);

  const toast = useToast();

  const { data: rawData, isLoading } = useBlocks(
    {
      limit: 10000,
    },
    {
      onError: (error: Error) => {
        toast({
          title: "Could not fetch blocks list",
          description: error.toString(),
          duration: 1_000,
          status: "error",
          isClosable: true,
        });
      },
      refetchInterval: 5000,
    },
  );

  React.useEffect(() => {
    if (onExpandChange) {
      onExpandChange(isExpanded);
    }
  }, [isExpanded, onExpandChange]);

  if (!isExpanded) {
    return (
      <Tooltip label="Expand blocks list">
        <IconButton
          aria-label="Expand blocks list"
          icon={<ChevronRightIcon />}
          onClick={() => setIsExpanded(true)}
        />
      </Tooltip>
    );
  }

  if (isLoading) {
    return (
      <VStack m={10}>
        <Spinner />
      </VStack>
    );
  }

  if (!rawData) {
    return (
      <VStack m={10}>
        <Text fontSize="x-small">Could not fetch blocks list</Text>
      </VStack>
    );
  }

  // Do it in the FE because why not...
  let data = rawData.data.filter((d) => {
    if (!filters.type && !filters.search) return false;
    return d.source_type === filters.type;
  });

  if (filters.search) {
    data = fuzzyFilter(rawData.data, filters.search, { fields: ["name"] }).map(
      (f) => f.item,
    );
  }

  return (
    <VStack spacing={1} ml={2} alignItems="start">
      {selectedBlock && (
        <BlockModal
          isOpen={true}
          onClose={() => setSelectedBlock(null)}
          blockId={selectedBlock.id}
        />
      )}
      <VStack alignItems="start" spacing={1} width="-webkit-fill-available">
        <HStack spacing={2} alignItems="center" mb={2}>
          <Heading size="md">You have {rawData.total} blocks</Heading>
          <Tooltip
            label={
              "A block is a piece of data that you can use in your operations. It may come from a Google Sheet, a database, or even the output of another operation."
            }
          >
            <InfoIcon boxSize={3} />
          </Tooltip>
          <Spacer />
          <Tooltip label="Condense blocks list">
            <IconButton
              aria-label="Condense blocks list"
              variant="ghost"
              icon={<ChevronLeftIcon />}
              onClick={() => setIsExpanded(false)}
            />
          </Tooltip>
        </HStack>
        <Select
          placeholder="Select a block type"
          onChange={({ target }) =>
            setFilters((f) => ({ ...f, type: target.value || null }))
          }
        >
          <option value="google_sheet">
            Google Sheet (
            {
              rawData.data.filter((d) => d.source_type === "google_sheet")
                .length
            }
            )
          </option>
          <option value="s3_location">
            Operation output (
            {rawData.data.filter((d) => d.source_type === "s3_location").length}
            )
          </option>
          <option disabled value="coming_soon">
            More coming soon...
          </option>
        </Select>
        <Input
          placeholder="Fuzzy search by block name"
          value={filters.search || undefined}
          onChange={({ target }) =>
            setFilters((f) => ({ ...f, search: target.value || null }))
          }
        />
      </VStack>
      <Box
        h={["200px", null, "calc(100vh - 104px)"]}
        w="100%"
        overflow="auto"
        css={{
          "&::-webkit-scrollbar": {
            height: "var(--chakra-sizes-1)",
            width: "var(--chakra-sizes-1)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "var(--chakra-colors-gray-400)",
          },
        }}
      >
        {!data.length && (
          <Text fontSize="xs" p={2}>
            Adapt your filters to see blocks 🧐
          </Text>
        )}
        <List m={2} overflowX="auto">
          {data.map((b) => (
            <ListItem
              key={b.id}
              mt={2}
              onClick={() => setSelectedBlock(b)}
              sx={{ cursor: "pointer" }}
            >
              <BlockTag block={b} />
            </ListItem>
          ))}
        </List>
      </Box>
    </VStack>
  );
};

export default BlocksList;
