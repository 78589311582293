import { Workflow, WorkflowPatchParams } from "../blocks.types";
import { API_URL } from "./auth";

export const patchWorkflow = async ({
  token,
  id,
  params,
}: {
  token: string | null;
  id: string;
  params: WorkflowPatchParams;
}): Promise<Workflow> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const resp = await fetch(API_URL + `/api/b/workflows/${id}`, {
    method: "PATCH",
    headers,
    body: JSON.stringify(params),
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not patch workflow due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
