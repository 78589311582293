import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { getWorkflowGraph } from "../api/get-workflow-graph";

export const useWorkflowGraph = (id: string, opts?: any) => {
  const { token } = useClerkToken();

  return useQuery(
    ["WORKFLOWS", id, "GRAPH"],
    () => getWorkflowGraph(token, id),
    {
      ...(opts || {}),
      // Default
      enabled: !!token && (opts?.enabled ?? true),
    },
  );
};
