import { API_URL } from "./auth";

export const REQUIRED_SCOPES = [
  "https://www.googleapis.com/auth/drive.metadata.readonly",
  "https://www.googleapis.com/auth/spreadsheets.readonly",
];

export type UserScopes = {
  oauth_google: string[];
};

export const getUserScopes = async (
  token: string | null,
): Promise<UserScopes> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const resp = await fetch(API_URL + "/api/user/scopes", {
    method: "GET",
    headers,
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not fetch user scopes due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
