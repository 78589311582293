import { API_URL } from "./auth";

export type ScheduleRun = {
  id: string;
  destination_url?: string;
  run_status: "success" | "failed";
  created_at: Date;
  user_id: string;
  saved_query_id: string;
  api_version: string;
  process_time: number;
};

export const getScheduleRuns = async ({
  token,
  queryId,
}: {
  token: string | null;
  queryId: string;
}): Promise<ScheduleRun[]> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const resp = await fetch(API_URL + `/api/jobs-user/${queryId}/runs`, {
    method: "GET",
    headers,
  });

  if (resp.status !== 200) {
    let error = `Could not get schedule runs due to ${resp.status} HTTP error code`;

    if (resp.status < 500 && resp.status >= 400) {
      const json = await resp.json();
      error = json["detail"];
    }

    throw new Error(error);
  }

  return (await resp.json())["data"];
};
