import { SheetQueryResult } from "./api/preview-sheet-query";
import { msToTime } from "./utils";
import DataTableContent from "./DataTableContent";

type Props = {
  data: SheetQueryResult & { processed_in?: number };
};

const DataTable = ({
  data: { data, elapsed, processed_in, cache_hit },
}: Props) => {
  let elapsedStr = elapsed ? msToTime(elapsed) : null;
  let processedStr = processed_in
    ? " and processed in " + msToTime(processed_in)
    : "";

  let caption: string | undefined = undefined;
  let cacheHitStr = cache_hit ? " (cache hit)" : "";

  if (elapsedStr) {
    caption = `Query ran in ${elapsedStr} ${processedStr} ${cacheHitStr}`;
  }

  return <DataTableContent data={data} caption={caption} showCaption />;
};

export default DataTable;
