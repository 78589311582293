import {
  Alert,
  AlertDescription,
  AlertIcon,
  AspectRatio,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Container,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spacer,
  Spinner,
  Tag,
  Text,
  Tooltip,
  VStack,
  useToast,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { SheetSQLCodeMirrorEditor } from "./CodeMirrorEditor";
import React from "react";
import {
  ArrowRightIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  DeleteIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import { VscRunCoverage as RunIcon } from "react-icons/vsc";
import DataTableContent from "./DataTableContent";
import { useDefaultWorkflow } from "./hooks/use-default-workflow";
import { Op } from "./blocks.types";
import { useUpsertOp } from "./hooks/use-upsert-op";
import { usePreviewOp } from "./hooks/use-preview-op";
import { msToTime } from "./utils";
import { useBlock } from "./hooks/use-block";
import BlockModal from "./BlockModal";
import BlockTag from "./BlockTag";
import { useDeleteOp } from "./hooks/use-delete-op";
import { useWorkflowGraph } from "./hooks/use-workflow-graph";
import { GraphCanvas } from "reagraph";
import { useRunWorkflowSync } from "./hooks/use-run-workflow-sync";
import { useWorkflowRuns } from "./hooks/use-workflow-runs";
import { useRunWorkflowAsync } from "./hooks/use-run-workflow-async";
import { useWorkflowRunById } from "./hooks/use-workflow-run-by-id";
import { useBlocks } from "./hooks/use-blocks";
import { useQueryClient } from "react-query";
import AiQueryButton from "./AiQueryButton";

type BlockInfoTagProps = {
  id: string;
};

const BlockInfoTag = ({ id }: BlockInfoTagProps) => {
  const [open, setOpen] = React.useState(false);

  const { data, isLoading } = useBlock(id);

  if (isLoading) {
    return (
      <Tag size="md" colorScheme="gray">
        Loading...
      </Tag>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Box>
      <BlockTag block={data} onClick={() => setOpen(true)} />
      <BlockModal blockId={id} onClose={() => setOpen(false)} isOpen={open} />
    </Box>
  );
};

type BlockOutputSelectProps = {
  placeholder?: string;
  value: string;
  onChange: (v: string) => void;
};

const BlockOutputSelect = ({
  placeholder,
  value,
  onChange,
}: BlockOutputSelectProps) => {
  const { data } = useBlocks({});

  return (
    <Select
      placeholder={placeholder || "Select block output"}
      value={value}
      onChange={({ target }) => onChange(target.value)}
      maxW="xs"
    >
      {data?.data
        ?.filter((b) => b.source_type === "s3_location")
        .map((block) => (
          <option key={block.id} value={block.id}>
            {block.name}
          </option>
        ))}
    </Select>
  );
};

type OperationCardProps = {
  collapsable?: boolean;
  onRemove?: () => void;
  onClose?: () => void;
  operation?: Partial<Op>;
};

export const OperationCard = ({
  operation,
  onRemove,
  onClose,
  collapsable,
}: OperationCardProps) => {
  const toast = useToast();

  const [maybeOp, setMaybeOp] = React.useState<Partial<Op>>(operation || {});
  const [collapsed, setCollapsed] = React.useState(false);

  let canPreview = !!maybeOp.type;
  const qc = useQueryClient();

  if (maybeOp.type === "sql_saved_query") {
    canPreview = canPreview && (maybeOp.type_info?.query as any)?.length > 0;
  } else if (maybeOp.type === "email_notification") {
    canPreview = canPreview && (maybeOp.type_info?.email as any)?.length > 0;
  }

  const {
    data: previewData,
    error: previewError,
    isLoading: isPreviewLoading,
    refetch: refetchPreview,
    isSuccess: isPreviewSuccess,
    // TODO: Fix type
  } = usePreviewOp(maybeOp as any, {
    retry: false,
    enabled: canPreview,
  });

  const { mutate, isLoading, isSuccess } = useUpsertOp({
    onSuccess: () => {
      toast({
        id: "op-saved",
        title: "Operation saved!",
        duration: 1_000,
        status: "success",
        isClosable: true,
      });

      // Only remove if it's a new operation, i.e.
      // a create instead of an update
      if (!maybeOp?.id) {
        onRemove?.();
      }

      qc.invalidateQueries(["WOKFLOWS"]);
      qc.invalidateQueries(["OPS"]);
    },
    onError: (err: any) => {
      toast({
        id: "op-save-error",
        title: "Could not save operation...",
        description: (err as Error).toString(),
        duration: 5_000,
        status: "error",
        isClosable: true,
      });
    },
  });

  const { mutate: deleteOpMut, isLoading: isDeleting } = useDeleteOp({
    onSuccess: () => {
      toast({
        id: "op-deleted",
        title: "Operation deleted!",
        duration: 1_000,
        status: "success",
        isClosable: true,
      });

      onRemove?.();

      qc.invalidateQueries(["WOKFLOWS"]);
      qc.invalidateQueries(["OPS"]);
    },
  });

  React.useEffect(() => {
    if (canPreview) {
      refetchPreview();
    }
  }, [maybeOp, maybeOp?.type_info, maybeOp?.type, canPreview, isSuccess]);

  let typeName = "";

  if (maybeOp.type === "sql_saved_query") {
    typeName = "SheetSQL query";
  } else if (maybeOp.type === "email_notification") {
    typeName = "Email notification";
  }

  const exists = previewData?.exists === true;

  const deleteOp = () => {
    if (!exists) {
      onRemove?.();
    } else {
      if (!maybeOp.id) {
        return;
      }

      deleteOpMut(maybeOp.id);
    }
  };

  if (collapsed) {
    return (
      <Card>
        <CardHeader>
          <VStack spacing={2} alignItems="stretch">
            <HStack spacing={2} alignItems="baseline">
              <Editable
                placeholder="New operation..."
                value={maybeOp.name || undefined}
                onChange={(v) => setMaybeOp((op) => ({ ...op, name: v }))}
              >
                <EditablePreview />
                <Input as={EditableInput} />
              </Editable>
              <Text fontSize="xs" color="gray.600">
                {typeName}
              </Text>
              <Box flex={1} />
              <Tooltip label="Expand operation">
                <IconButton
                  aria-label="Expand operation"
                  variant="ghost"
                  size="sm"
                  onClick={() => setCollapsed(false)}
                  icon={<ChevronDownIcon />}
                />
              </Tooltip>
              <Tooltip label="Delete operation">
                <IconButton
                  aria-label="Delete operation"
                  isLoading={isDeleting}
                  disabled={isDeleting}
                  colorScheme="red"
                  size="sm"
                  onClick={deleteOp}
                  icon={<DeleteIcon />}
                />
              </Tooltip>
              <Tooltip label="Close panel">
                <IconButton
                  aria-label="Close operation panel"
                  colorScheme="gray"
                  size="sm"
                  onClick={onClose}
                  icon={<CloseIcon />}
                />
              </Tooltip>
            </HStack>
          </VStack>
        </CardHeader>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <VStack spacing={2} alignItems="stretch">
          <HStack spacing={2} alignItems="baseline">
            <Tooltip label="Click to edit the operation name">
              <Editable
                placeholder="New operation..."
                value={maybeOp.name || undefined}
                onChange={(v) => setMaybeOp((op) => ({ ...op, name: v }))}
              >
                <EditablePreview />
                <EditableInput />
              </Editable>
            </Tooltip>
            <Box flex={1} />
            {collapsable && (
              <Tooltip label="Collapse operation">
                <IconButton
                  aria-label="Collapse operation"
                  variant="ghost"
                  size="sm"
                  onClick={() => setCollapsed(true)}
                  icon={<ChevronUpIcon />}
                />
              </Tooltip>
            )}
            <Tooltip label="Delete operation">
              <IconButton
                aria-label="Delete operation"
                isLoading={isDeleting}
                disabled={isDeleting}
                colorScheme="red"
                size="sm"
                onClick={deleteOp}
                icon={<DeleteIcon />}
              />
            </Tooltip>
            <Tooltip label="Close panel">
              <IconButton
                aria-label="Close operation panel"
                colorScheme="gray"
                size="sm"
                onClick={onClose}
                icon={<CloseIcon />}
              />
            </Tooltip>
          </HStack>
          <Select
            placeholder="Select operation type"
            value={maybeOp?.type}
            onChange={({ target }) =>
              setMaybeOp((prev) => ({
                ...prev,
                type: (target.value || undefined) as Partial<Op>["type"],
              }))
            }
            maxW="xs"
          >
            <option value="sql_saved_query">SheetSQL AI Query ✨</option>
            <option value="email_notification">Email notification</option>
            <option value="assertion" disabled>
              Data assertion (coming soon)
            </option>
            <option value="assertion" disabled>
              API endpoint (coming soon)
            </option>
            <option value="assertion" disabled>
              Embedded chart (coming soon)
            </option>
          </Select>
        </VStack>
      </CardHeader>
      <CardBody>
        <VStack spacing={4} alignItems="stretch">
          {maybeOp.type &&
            (previewData?.block_ids?.length ||
              previewData?.destination_ids?.length) && (
              <HStack justifyContent="space-evenly">
                <VStack spacing={2} alignItems="center">
                  <Heading size="xs">Sources</Heading>
                  <HStack justifyContent="space-evenly">
                    {(previewData?.block_ids || []).map((blockId) => (
                      <BlockInfoTag key={blockId} id={blockId} />
                    ))}
                  </HStack>
                </VStack>
                <ArrowRightIcon />
                <VStack spacing={2} alignItems="center">
                  <Heading size="xs">Outputs</Heading>
                  <HStack spacing={2} justifyContent="space-evenly">
                    {(previewData?.destination_ids || []).map((blockId) => (
                      <BlockInfoTag key={blockId} id={blockId} />
                    ))}
                  </HStack>
                </VStack>
              </HStack>
            )}
          <VStack spacing={2} m={2} alignItems="center">
            {!maybeOp.type && (
              <Alert status="info" variant="top-accent">
                <AlertIcon />
                <AlertDescription>
                  Select an operation type to start!
                </AlertDescription>
              </Alert>
            )}
            {maybeOp.type === "sql_saved_query" && (
              <VStack spacing={2} w="full">
                <Box w="full" sx={{ border: "1px", borderColor: "gray" }}>
                  <SheetSQLCodeMirrorEditor
                    value={(maybeOp.type_info?.query || "") as string}
                    onChange={(value) =>
                      setMaybeOp((prev) => ({
                        ...prev,
                        type_info: { ...(prev.type_info || {}), query: value },
                      }))
                    }
                  />
                </Box>
                <AiQueryButton
                  onRun={() => null}
                  query={maybeOp.type_info?.query as string | undefined}
                  onChange={(value) =>
                    setMaybeOp((prev) => ({
                      ...prev,
                      type_info: { ...(prev.type_info || {}), query: value },
                    }))
                  }
                />
                <HStack spacing={4} alignItems="start" w="full">
                  <Tooltip label="Incremental queries will use the last run data from the destination (if any) and append the new data based on the de-duplication and keep criteria specified. If the query is not incremental, then the output of the operation (destination) will be replaced on each not skipped run.">
                    <VStack spacing={1} alignItems="start">
                      <Text fontSize="xs">Incremental query?</Text>
                      <Select
                        value={maybeOp?.type_info?.incremental ? "1" : "0"}
                        onChange={({ target }) =>
                          setMaybeOp((prev) => ({
                            ...prev,
                            type_info: {
                              ...(prev.type_info || {}),
                              incremental: target.value === "1",
                            },
                          }))
                        }
                        maxW="xs"
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </Select>
                    </VStack>
                  </Tooltip>
                  {((maybeOp?.type_info?.incremental as boolean) ||
                    undefined) && (
                    <Tooltip label="The de-duplication column/s are the column/s that will be used to de-duplicate the incremental data. Use this to define the criteria neeed to keep or remove new data from the operation. By default (if left empty), it will de-dup by all the columns.">
                      <VStack spacing={1} alignItems="start">
                        <Text fontSize="xs">
                          De-dup columns (comma separated)
                        </Text>
                        <Input
                          placeholder="De-dup key/s"
                          value={
                            (maybeOp.type_info?.incremental_dedup_keys ||
                              "") as string
                          }
                          onChange={({ target }) =>
                            setMaybeOp((prev) => ({
                              ...prev,
                              type_info: {
                                ...(prev.type_info || {}),
                                incremental_dedup_keys: target.value,
                              },
                            }))
                          }
                        />
                      </VStack>
                    </Tooltip>
                  )}
                  {((maybeOp?.type_info?.incremental as boolean) ||
                    undefined) && (
                    <Tooltip label="The keep order defines how the incremental data will be de-duplicated. If `first` is provided, then the previous data will be kept, if `last` then the new data will be kept.">
                      <VStack spacing={1} alignItems="start">
                        <Text fontSize="xs">Keep order</Text>
                        <Select
                          value={
                            (maybeOp?.type_info?.incremental_keep ||
                              "last") as string
                          }
                          onChange={({ target }) =>
                            setMaybeOp((prev) => ({
                              ...prev,
                              type_info: {
                                ...(prev.type_info || {}),
                                incremental_keep: target.value,
                              },
                            }))
                          }
                          maxW="xs"
                        >
                          <option value="first">First</option>
                          <option value="last">Last</option>
                        </Select>
                      </VStack>
                    </Tooltip>
                  )}
                </HStack>
              </VStack>
            )}
            {maybeOp.type === "email_notification" && (
              <Box w="full">
                <HStack>
                  <Text>Email</Text>
                  <Input
                    placeholder="Email address"
                    maxW="240px"
                    isInvalid={
                      !((maybeOp.type_info?.email || "") as string).match(
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      )
                    }
                    type="email"
                    value={(maybeOp.type_info?.email || "") as string}
                    onChange={({ target }) =>
                      setMaybeOp((prev) => ({
                        ...prev,
                        type_info: {
                          ...(prev.type_info || {}),
                          email: target.value,
                        },
                      }))
                    }
                  />
                  <Text>when the</Text>
                  <BlockOutputSelect
                    placeholder="output block"
                    value={(maybeOp.type_info?.email_op_id || "") as string}
                    onChange={(value) =>
                      setMaybeOp((prev) => ({
                        ...prev,
                        type_info: {
                          ...(prev.type_info || {}),
                          email_op_id: value,
                        },
                      }))
                    }
                  />
                  <Text>changes.</Text>
                </HStack>
              </Box>
            )}
          </VStack>
          {!!previewError && (
            <VStack spacing={2} alignItems="center">
              <Text color="red.500" fontSize="xs" fontStyle="italic">
                Error previewing operation
                {previewError.toString()}
              </Text>
            </VStack>
          )}
          <VStack spacing={2} alignItems="stretch">
            <Center display="flex" flexDirection="column">
              {previewData?.data && <Heading size="xs">Preview</Heading>}
              {previewData?.data && (
                <Text fontSize="xs" color="gray.800">
                  Operation processed in{" "}
                  {msToTime(previewData.data.process_time)} and returned a
                  preview of {previewData.data.data.data.length} rows of a total
                  of <b>{previewData.data.count} rows</b>.
                </Text>
              )}
            </Center>
            <VStack spacing={4} alignItems="stretch">
              {previewData?.data && (
                <Box flex={1}>
                  <DataTableContent
                    data={previewData.data.data}
                    defaultRows={100}
                    showCaption={false}
                    maxH="420px"
                  />
                </Box>
              )}
              {previewData?.data?.email_body && (
                <Heading size="sm">Email body</Heading>
              )}
              {previewData?.data?.email_body && (
                <Box maxH="260px" overflowY="auto" maxW="3xl">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {previewData.data.email_body}
                  </ReactMarkdown>
                </Box>
              )}
            </VStack>
          </VStack>
          {maybeOp.type && (
            <HStack>
              <Box flex={1} />
              <Tooltip label="By saving the operation, it will become part of the workflow. This means that it will be ran as part of the whole workflow execution plan.">
                <Button
                  size="sm"
                  colorScheme="purple"
                  isLoading={isLoading || isPreviewLoading}
                  isDisabled={
                    !isPreviewSuccess ||
                    isLoading ||
                    isPreviewLoading ||
                    !!previewError
                  }
                  onClick={() => mutate({ dry_run: false, ...maybeOp } as any)}
                >
                  {exists ? "Update" : "Save"} operation
                </Button>
              </Tooltip>
            </HStack>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};

type WorkflowRunDetailsProps = {
  wfId: string;
  runId: number;
};

const WorkflowRunDetails = ({ wfId, runId }: WorkflowRunDetailsProps) => {
  const { data: run, isLoading: isRunLoading } = useWorkflowRunById(
    wfId,
    runId,
    {
      refetchInterval: 2_500,
    },
  );

  if (isRunLoading) {
    return <Spinner />;
  }

  if (!run) {
    return null;
  }

  return (
    <VStack spacing={2} alignItems="stretch">
      <Text fontSize="xs" p={1}>
        This workflow (<code>#{run.id}</code>) ran{" "}
        <b>{run.data.result?.length || "N/A"}</b> operations
      </Text>
      {run.data.result?.map((result, ix) => (
        <Box key={`${ix}`} p={1}>
          <VStack spacing={2} alignItems="start">
            <Text fontSize="xs">
              <b>
                Operation #{ix + 1}{" "}
                {result.op_name ? `"${result.op_name}"` : ""}
              </b>
            </Text>
            <Text fontSize="xs">
              {result.status.toLocaleUpperCase()} in{" "}
              {msToTime(result.process_time)}
            </Text>
            <Text fontSize="xs">
              Hash{" "}
              <Tooltip label={result.hash}>
                <code>
                  {result.hash.slice(0, 4)}...{result.hash.slice(-4)}
                </code>
              </Tooltip>
            </Text>
          </VStack>
          {ix !== (run.data.result?.length || 0) - 1 && <Divider pt={2} />}
        </Box>
      ))}
    </VStack>
  );
};

type WorkflowGraphProps = {
  wfId: string;
  showGraph: boolean;
  isOpen: boolean;
  onClose: () => void;
};

export const WorkflowDetailsModal = ({
  wfId,
  isOpen,
  onClose,
  showGraph,
}: WorkflowGraphProps) => {
  const [nodeClicked, onNodeClick] = React.useState<string | null>(null);
  const [selectedRuns, setSelectedRuns] = React.useState(new Set<number>());

  const {
    data: graph,
    isLoading: isGraphLoading,
    error: graphError,
  } = useWorkflowGraph(wfId, {
    enabled: isOpen,
  });

  const {
    data: runs,
    isLoading: isRunsLoading,
    error: runsError,
  } = useWorkflowRuns(wfId, {
    refetchInterval: 2_500,
  });

  const getRunIcon = (status: string) => {
    if (status === "finished") {
      return <Icon as={CheckIcon} color="green.500" />;
    } else if (status === "failed") {
      return <Icon as={CloseIcon} color="red.500" />;
    } else if (status === "enqueued") {
      return <Icon as={TimeIcon} color="gray.500" />;
    } else {
      return <Spinner />;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={showGraph ? "6xl" : "2xl"}>
      <ModalOverlay
        zIndex={100}
        bg="blackAlpha.200"
        backdropFilter="blur(10px) hue-rotate(45deg)"
      />
      <ModalContent>
        <ModalHeader>Workflow runs</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid columns={showGraph ? 2 : 1}>
            <VStack alignItems="stretch">
              <HStack spacing={2}>
                <Tooltip label="Only the last 25 jobs will be shown in the list">
                  <Heading size="md">Runs</Heading>
                </Tooltip>
                <Spacer />
                <WorkflowRunButton sync={false} />
              </HStack>
              {isRunsLoading && <Spinner />}
              {!!runsError && (
                <Text>Couldn't load the runs. Try again later.</Text>
              )}
              {runs && (
                <VStack
                  spacing={1}
                  alignItems="stretch"
                  overflowY="auto"
                  maxH="600px"
                  mb={8}
                >
                  {runs.map((run) => (
                    <Box key={run.id} p={2} borderWidth="1px" borderRadius="md">
                      <HStack spacing={3} ml={1}>
                        <Tooltip label={run.status}>
                          {getRunIcon(run.status)}
                        </Tooltip>
                        <Text>
                          <code>
                            {new Date(run.enqueued_at).toLocaleString()}{" "}
                            {run.elapsed_ms && "in " + msToTime(run.elapsed_ms)}
                          </code>
                        </Text>
                        <Spacer />
                        <IconButton
                          aria-label="Show details"
                          variant="ghost"
                          icon={
                            selectedRuns.has(run.id) ? (
                              <ChevronUpIcon />
                            ) : (
                              <ChevronDownIcon />
                            )
                          }
                          onClick={() => {
                            if (selectedRuns.has(run.id)) {
                              selectedRuns.delete(run.id);
                            } else {
                              selectedRuns.add(run.id);
                            }

                            setSelectedRuns(new Set(selectedRuns));
                          }}
                        />
                      </HStack>
                      {selectedRuns.has(run.id) && (
                        <WorkflowRunDetails wfId={wfId} runId={run.id} />
                      )}
                    </Box>
                  ))}
                </VStack>
              )}
            </VStack>
            {showGraph && (
              <Container>
                {isGraphLoading && <Spinner />}
                {!!graphError && (
                  <Text>Coudn't load the workflow graph. Try again later.</Text>
                )}
                {graph && (
                  <AspectRatio ratio={1}>
                    <GraphCanvas
                      layoutType="treeLr2d"
                      labelType="all"
                      draggable
                      minDistance={1200}
                      maxNodeSize={8}
                      nodes={graph.data.nodes}
                      edges={graph.data.edges}
                      onNodeClick={(n: any) =>
                        n.type === "block" ? onNodeClick(n.id) : null
                      }
                    />
                  </AspectRatio>
                )}
              </Container>
            )}
          </SimpleGrid>
        </ModalBody>
        {nodeClicked && (
          <BlockModal
            blockId={nodeClicked}
            isOpen={!!nodeClicked}
            onClose={() => onNodeClick(null)}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

type WorkflowRunButtonProps = {
  sync?: boolean;
};

const WorkflowRunButton = ({ sync }: WorkflowRunButtonProps) => {
  const toast = useToast();

  const { data: wf, isLoading } = useDefaultWorkflow();

  const { mutate: execute, isLoading: isExecuting } = (
    sync ? useRunWorkflowSync : useRunWorkflowAsync
  )({
    onSuccess: () => {
      toast({
        title: "Workflow run started!",
        description: "Check the runs in the workflow details.",
        duration: 1_000,
        status: "loading",
        isClosable: true,
      });
    },
  });

  const disabled = !wf?.enabled;
  let label = "Trigger a workflow run";

  if (disabled) {
    label = "Workflow is disabled";
  }

  if (!wf) {
    return null;
  }

  return (
    <Tooltip label={label}>
      <Button
        size="xs"
        colorScheme="purple"
        onClick={() => (execute as any)({ id: wf.id })}
        isLoading={isExecuting}
        isDisabled={isLoading || isExecuting || !wf?.enabled}
      >
        <HStack spacing={2} alignItems="center">
          <Icon as={RunIcon} />
          <Text fontSize="xs">{sync ? "Run" : "Schedule run"}</Text>
        </HStack>
      </Button>
    </Tooltip>
  );
};
