import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { getSyncSheets } from "../api/get-sync-sheets";

export const useSyncSheets = (opts?: any) => {
  const { token } = useClerkToken();

  return useQuery(["SYNC_SHEET_FILES"], () => getSyncSheets({ token }), {
    ...(opts || {}),
    // Default
    enabled: !!token,
    retry: true,
  });
};
