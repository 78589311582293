import { useQuery } from "react-query";
import { getScheduleRuns } from "./api/get-schedule-runs";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Skeleton,
  Link,
} from "@chakra-ui/react";
import { useClerkToken } from "./providers/clerk-token";

type Props = {
  queryId: string;
};

const ScheduleRunsTable = ({ queryId }: Props) => {
  const { token } = useClerkToken();

  const { data: runs, isLoading } = useQuery(
    ["GET_SCHEDULE_RUNS_USER", queryId],
    () => getScheduleRuns({ token, queryId }),
    {
      enabled: !!token && !!queryId,
      refetchOnWindowFocus: true,
      refetchInterval: 5_000,
    },
  );

  if (isLoading) {
    return <Skeleton />;
  }

  const cleanRuns = (runs || []).sort(
    // Decresing order
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  if (!cleanRuns.length) {
    return null;
  }

  return (
    <TableContainer overflowX="unset" overflowY="unset">
      <Table variant="striped" size="sm">
        <Thead position="sticky" top={0} zIndex="docked" background="white">
          <Tr>
            <Th></Th>
            <Th>Ran At</Th>
            <Th>Elapsed (s)</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {cleanRuns.map((d) => (
            <Tr key={`row_${d.id}`}>
              <Td>{d.run_status === "success" ? "✅" : "❌"}</Td>
              <Td>{new Date(d.created_at).toLocaleString()}</Td>
              <Td>{d.process_time.toFixed(2)}</Td>
              <Td>
                {d.destination_url && (
                  <Link color="purple.800" href={d.destination_url} isExternal>
                    See
                  </Link>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ScheduleRunsTable;
