import { SignUpButton } from "@clerk/clerk-react";
import {
  Link,
  VStack,
  Heading,
  Text,
  AspectRatio,
  Container,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  List,
  ListItem,
  ListIcon,
  Button,
  Box,
  useBreakpointValue,
  Tag,
  HStack,
  Tooltip,
  Center,
  Image,
} from "@chakra-ui/react";
import {
  CheckIcon,
  CloseIcon,
  UnlockIcon,
  ViewOffIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import { useSendAppEvent } from "./hooks/use-send-app-event";

const Landing = () => {
  const containerW = useBreakpointValue({
    base: "container.2xl",
    sm: "container.2xl",
    md: "container.2xl",
    lg: "container.xl",
    xl: "container.2xl",
    "2xl": "container.xl",
  });

  const isBig = useBreakpointValue({
    base: false,
    sm: false,
    md: true,
    lg: true,
    xl: true,
    "2xl": true,
  });

  const { mutate: ev } = useSendAppEvent();

  return (
    <Container maxW={containerW}>
      <VStack spacing={10}>
        <Box id="heading" pt={8} m={2} maxW="container.lg">
          <VStack>
            <Heading size="2xl">Google Sheets & SQL: The Power Duo</Heading>
            <Text fontSize="md" align="left" mt={2}>
              Imagine the possibilities when Sheets and SQL join forces — your
              data, redefined.
            </Text>
          </VStack>
        </Box>
        <Box id="loom" className="landing-section">
          <VStack spacing={2} alignItems="center" maxW="container.lg">
            <HStack>
              <Tag size="md" colorScheme="purple">
                New!
              </Tag>
              <Heading size="lg">SheetSQL AI is here! 🚀</Heading>
            </HStack>
            <Box
              p={2}
              borderWidth="1px"
              borderRadius="lg"
              boxShadow="lg"
              w={isBig ? "container.md" : "320px"}
            >
              <AspectRatio ratio={16 / 9}>
                <iframe
                  src="https://www.loom.com/embed/2ae96fd360594df7ba7ac156f60cec4c?sid=78641973-7726-4d74-bb76-1b656581956f"
                  title="Loom: SheetSQL AI is here!"
                  allowFullScreen
                  loading="lazy"
                  allow="clipboard-write"
                />
              </AspectRatio>
            </Box>
            <Button
              size={isBig ? "lg" : "md"}
              m={2}
              w={isBig ? "480px" : "180px"}
              colorScheme="purple"
              onClick={(e) => {
                e.preventDefault();
                ev({
                  ty: "register_intent",
                  data: {
                    from: "loom_ai",
                  },
                });
              }}
            >
              <SignUpButton mode="modal">
                {isBig
                  ? "Want ask questions to your sheets? Try it out for free!"
                  : "Try it out for free!"}
              </SignUpButton>
            </Button>
            <HStack>
              <Link
                href="https://www.producthunt.com/posts/sheetsql-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-sheetsql&#0045;2"
                rel="noreferrer"
                isExternal
                onClick={() =>
                  ev({
                    ty: "landing_click",
                    data: { link: "product_hunt_badge" },
                  })
                }
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=459714&theme=light"
                  alt="SheetSQL - Supercharge&#0032;Your&#0032;Sheets&#0032;with&#0032;SQL&#0032;in&#0032;Seconds&#0032;💨 | Product Hunt"
                  style={
                    isBig
                      ? {
                          width: "235px",
                          height: "54px",
                        }
                      : {
                          width: "148px",
                          height: "34px",
                        }
                  }
                />
              </Link>
              <Button
                variant="outline"
                color="#7299ee"
                colorScheme="black"
                width={isBig ? "235px" : "148px"}
                height={isBig ? "50px" : "34px"}
                onClick={() => {
                  ev({
                    ty: "landing_click",
                    data: { link: "discord" },
                  });

                  window.open("https://discord.gg/N4crT77dXQ", "_blank");
                }}
              >
                <HStack spacing={2} alignItems="center">
                  <Image
                    src="https://www.pngall.com/wp-content/uploads/13/Discord-Logo-PNG-Images.png"
                    height={isBig ? "34px" : "22px"}
                  />
                  <Text fontSize={isBig ? "lg" : "sm"}>Join discord</Text>
                </HStack>
              </Button>
            </HStack>
          </VStack>
        </Box>
        <Box id="features" className="landing-section">
          <Box m={2} maxW="90vw">
            <VStack spacing={2} alignItems="center">
              <VStack spacing={4} alignItems="left" maxW="container.lg" mt={4}>
                <VStack spacing={2} alignItems="center" mb={2}>
                  <HStack spacing={2}>
                    <Tag size="md" colorScheme="purple">
                      New!
                    </Tag>
                    <Heading size="lg" noOfLines={2}>
                      Feel the power of SheetSQL AI ✨
                    </Heading>
                  </HStack>
                </VStack>
                <VStack spacing={4} alignItems="left">
                  <List spacing={2}>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>Ask questions to your sheets</b>: Use AI to ask
                      questions about your data. Get answers in seconds!
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>Explain your queries</b>: Get explanations for your
                      queries. Understand what's happening under the hood.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>Generate queries with ease</b>: Use natural language
                      prompts to generate SQL queries. Write queries without
                      writing queries!
                    </ListItem>
                  </List>
                  <Text fontSize="small">
                    Stay tuned for more features on the horizon... 🤫
                  </Text>
                </VStack>
              </VStack>
              <Center m={10}>
                <Box
                  p={2}
                  borderWidth="1px"
                  borderRadius="lg"
                  boxShadow="lg"
                  w={isBig ? "container.md" : "320px"}
                >
                  <AspectRatio ratio={16 / 9}>
                    <iframe
                      src="https://www.loom.com/embed/d08d6237d77e4bdaa3a7e6bd5c7e7776?sid=cb1273d2-934f-4cfb-9a03-bbc5cfff011a"
                      title="Loom: SheetSQL intro tour"
                      allowFullScreen
                      loading="lazy"
                      allow="clipboard-write"
                    />
                  </AspectRatio>
                </Box>
              </Center>
              <VStack spacing={4} alignItems="left" maxW="container.lg" mt={4}>
                <VStack spacing={2} alignItems="center" mb={2}>
                  <Heading size="lg">
                    Explore Your Sheets with SQL Queries in Seconds 🚀
                  </Heading>
                </VStack>
                <VStack spacing={4} alignItems="left">
                  <List spacing={2}>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>Instant SQL Magic</b>: Transform your Google Sheets
                      data with SQL queries — get live results in a flash 🚀
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>Effortless Data Export</b>: See it, click it, export
                      it. Move your query results to new sheets effortlessly
                      with just one click.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>Boundless Data Joining</b>: Seamlessly merge data
                      across multiple sheets and worksheets — experience the
                      freedom of limitless* <code>JOIN</code>s.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>Smart Query Saving</b>: Save your SQL brilliance in a
                      user-friendly editor. Write once, use anytime.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>Automated Exports</b>: Schedule your exports
                      effortlessly. Set up a <code>CRON</code> schedule with a
                      couple of clicks, and you're good to go.
                    </ListItem>
                  </List>
                  <Text fontSize="xs">
                    * For example, joining three sheets with over 100,000
                    records each? Just a few seconds. And that’s while opening
                    those same sheets in your browser nearly takes a minute...
                  </Text>
                </VStack>
              </VStack>
              <VStack spacing={4} alignItems="left" maxW="container.lg" mt={10}>
                <VStack spacing={2} alignItems="center" mb={2}>
                  <Heading size="lg" noOfLines={2}>
                    Use Workflows to Automate Your Tedious Processes 🤖
                  </Heading>
                </VStack>
                <VStack spacing={4} alignItems="left">
                  <List spacing={2}>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>Create a workflows in minutes</b>: Use simple SQL
                      queries to automate your data needs. Write data pipelines
                      as if you were a{" "}
                      <Link
                        color="purple.800"
                        href="https://www.getdbt.com/"
                        isExternal
                        onClick={() =>
                          ev({ ty: "landing_click", data: { link: "dbt" } })
                        }
                      >
                        DBT
                      </Link>{" "}
                      pro!
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>Get notified... when needed</b>: Receive notifications
                      when your workflows run and the results have changed.
                      Forget about spammy notifications 📬
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green.500" />
                      <b>See your workflow graph</b>: Visualize your workflow
                      with a graph that shows all your queries and their
                      dependencies.
                    </ListItem>
                  </List>
                </VStack>
              </VStack>
            </VStack>
          </Box>
        </Box>
        <Box id="pricing" className="landing-section">
          <Box m={2} maxW="container.lg">
            <VStack spacing={2} alignItems="center">
              <Heading>Pricing</Heading>
              <SimpleGrid
                m={4}
                columns={[1, 1, 2, 2]}
                spacing={[4, 4, 10, 20]}
                alignItems="stretch"
                justifyItems="stretch"
              >
                <Card p={2}>
                  <CardHeader>
                    <Heading size="md">Free 💸</Heading>
                    <Text fontSize="lg">No credit card required</Text>
                    <Text fontSize="xs">Perfect for getting started!</Text>
                  </CardHeader>
                  <CardBody>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        Access <b>all your sheets</b>
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        <b>
                          30{"  "}
                          <Tooltip label="Each successful query in the explorer, and queries ran in workflows (not skipped) will use 1 credit.">
                            <span className="underline-dots">
                              query credits
                            </span>
                          </Tooltip>
                        </b>{" "}
                        per month
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        <b>
                          100{"  "}
                          <Tooltip label="Each successful workflow run will use 1 credit.">
                            <span className="underline-dots">
                              workflow credits
                            </span>
                          </Tooltip>
                        </b>{" "}
                        per month
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        <b>
                          10{"  "}
                          <Tooltip label="Using either the explain or generate feature will use 1 credit each time.">
                            <span className="underline-dots">AI credits</span>
                          </Tooltip>
                        </b>{" "}
                        per month
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        Save <b>∞</b> queries
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CloseIcon} color="red.500" />
                        No{" "}
                        <Tooltip label="Scheduled exports are queries that are run on a specified scheduled, and saved into a spreadsheet after each execution.">
                          <b className="underline-dots">scheduled exports</b>
                        </Tooltip>
                      </ListItem>
                    </List>
                  </CardBody>
                </Card>
                <Card p={2}>
                  <CardHeader>
                    <Heading size="md">PRO 🚀</Heading>
                    <Text fontSize="lg">$5 / month</Text>
                    <Text fontSize="xs">For the powerusers!</Text>
                  </CardHeader>
                  <CardBody>
                    <List spacing={3}>
                      <ListItem>
                        Everything included in the free plan and ...
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        <b>
                          ∞ {"  "}
                          query credits
                        </b>{" "}
                        per month
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        <b>
                          ∞ {"  "}
                          workflow credits
                        </b>{" "}
                        per month
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        <b>
                          1000 {"  "}
                          AI credits
                        </b>{" "}
                        per month
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        <b>Scheduled exports</b>
                      </ListItem>
                      <ListItem>
                        <ListIcon as={UnlockIcon} color="green.500" />
                        Automatic access to <b>new paid features</b>
                      </ListItem>
                      <ListItem>
                        <ListIcon as={ViewOffIcon} color="green.500" />
                        15-day <b>no-questions-asked</b> refund policy
                      </ListItem>
                      <ListItem>
                        <ListIcon as={TimeIcon} color="green.500" />
                        <b>Cancel anytime</b> — no strings attached
                      </ListItem>
                    </List>
                  </CardBody>
                </Card>
              </SimpleGrid>
              <Button
                size="lg"
                m={2}
                colorScheme="purple"
                onClick={(e) => {
                  e.preventDefault();
                  ev({
                    ty: "register_intent",
                    data: {
                      from: "pricing",
                    },
                  });
                }}
              >
                <SignUpButton mode="modal">
                  Try the power of SheetSQL for free
                </SignUpButton>
              </Button>
            </VStack>
          </Box>
        </Box>
        <Box id="privacy" className="landing-section">
          <Box m={2} maxW="container.lg">
            <VStack spacing={2} alignItems="center">
              <Heading>Your Data, Your Rules.</Heading>
              <Text>
                <b>
                  At <code>SheetSQL</code>, we treat the privacy of your data
                  with the utmost importance
                </b>
                . Our only use of your data is to enhance your experience — no
                exceptions, no under-the-table sharing. We access only the
                essentials to power your SQL endeavors, and not a byte more.
                <b>Your control is paramount</b>: manage and secure your data
                with full transparency and ease.
              </Text>
              <Text>
                Enjoy the full potential of <code>SheetSQL</code> while
                maintaining peace of mind. And remember,{" "}
                <b>
                  your Google Sheets connection is yours to revoke at any moment
                </b>{" "}
                — freedom guaranteed.
              </Text>
            </VStack>
          </Box>
        </Box>
        <Box id="contact" className="landing-section">
          <Box m={2} maxW="container.lg">
            <VStack spacing={2} alignItems="center">
              <Heading>Get in Touch</Heading>
              <Text fontSize="md">
                Questions? Suggestions? Encountered a glitch? Whatever it is,
                I'm all ears. Drop me a line at{" "}
                <Link
                  color="purple.800"
                  href="mailto:taras@sheetsql.io"
                  isExternal
                  onClick={() =>
                    ev({ ty: "landing_click", data: { link: "email" } })
                  }
                >
                  taras@sheetsql.io
                </Link>
                , and I'll be on the other side, ready to chat, assist, and
                tackle any challenges together.{" "}
                <b>Your input drives us forward</b>!
              </Text>
            </VStack>
          </Box>
        </Box>
        <Box id="footer" className="landing-section">
          <Box m={2} maxW="container.lg">
            <VStack spacing={1} alignItems="center">
              <Text fontSize="xs">
                <Link
                  color="purple.800"
                  href="/privacy-policy.pdf"
                  isExternal
                  onClick={() =>
                    ev({
                      ty: "landing_click",
                      data: { link: "privacy_policy" },
                    })
                  }
                >
                  Privacy Policy
                </Link>{" "}
                and{" "}
                <Link
                  color="purple.800"
                  href="/terms.pdf"
                  isExternal
                  onClick={() =>
                    ev({ ty: "landing_click", data: { link: "terms" } })
                  }
                >
                  Terms & Conditions
                </Link>
              </Text>
              <Text fontSize="xs">
                Made with 💜 by{" "}
                <Link
                  color="purple.800"
                  href="https://tarasyarema.com?utm_source=sheetsql"
                  isExternal
                  onClick={() =>
                    ev({
                      ty: "landing_click",
                      data: { link: "tarasyarema.com" },
                    })
                  }
                >
                  Taras Yarema
                </Link>
              </Text>
            </VStack>
          </Box>
        </Box>
      </VStack>
    </Container>
  );
};

export default Landing;
