import { SignInButton, SignUpButton, UserButton } from "@clerk/clerk-react";
import {
  Image,
  Spacer,
  Heading,
  Button,
  Flex,
  useBreakpointValue,
  Text,
  Tooltip,
  Container,
  HStack,
  Badge,
  SimpleGrid,
  IconButton,
} from "@chakra-ui/react";
import Usage from "./Usage";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserScopes } from "./hooks/use-user-scopes";
import { REQUIRED_SCOPES } from "./api/get-user-scopes";
import { useSendAppEvent } from "./hooks/use-send-app-event";
import React from "react";
import { EmailIcon, InfoIcon } from "@chakra-ui/icons";
import { useUser } from "./hooks/use-user";

const MANTEINANCE_MODE =
  import.meta.env.VITE_MANTEINANCE_MODE === "true" ? true : false;

type Props = {
  signedIn?: boolean;
};

const LoggedInComponents = () => {
  const { data, isLoading } = useUserScopes();
  const { data: user } = useUser();

  const navigate = useNavigate();
  const loc = useLocation();

  const hasAccess =
    isLoading ||
    (!!data?.oauth_google &&
      REQUIRED_SCOPES.every((scope) => data.oauth_google.includes(scope)));

  return (
    <HStack spacing={2} flex={1}>
      <Spacer />
      <Tooltip label="Go to the Sheet explorer">
        <Button
          size="xs"
          variant={loc.pathname === "/" ? "solid" : "ghost"}
          colorScheme={loc.pathname === "/" ? "purple" : "gray"}
          onClick={() => navigate("/")}
        >
          Explorer
        </Button>
      </Tooltip>
      <Tooltip label="NEW: Go to the workflows page">
        <Button
          size="xs"
          variant={loc.pathname === "/workflows" ? "solid" : "ghost"}
          colorScheme={loc.pathname === "/workflows" ? "purple" : "gray"}
          onClick={() => navigate("/workflows")}
        >
          Workflows
        </Button>
      </Tooltip>
      <Spacer />
      {user && (
        <Tooltip label="Your SheetSQL version">
          <Text fontSize="x-small">
            <code>v{user.version}</code>
          </Text>
        </Tooltip>
      )}
      <HStack spacing={2} alignItems="center">
        <Usage />
        {!hasAccess && (
          <Tooltip label="You need to grant access to Google Sheets to use SheetSQL, to do so you by going to your profile management page!">
            <Badge colorScheme="red" variant="subtle">
              Missing access
            </Badge>
          </Tooltip>
        )}
        <Tooltip label="See your profile">
          <UserButton afterSignOutUrl="/" />
        </Tooltip>
      </HStack>
    </HStack>
  );
};

const Header = ({ signedIn }: Props) => {
  const isBigHeader = useBreakpointValue({
    base: false,
    sm: false,
    md: true,
    lg: true,
    xl: true,
    "2xl": true,
  });

  const { mutate: ev } = useSendAppEvent();

  const firstCall = () =>
    ev({
      ty: signedIn ? "app_visit" : "landing_visit",
      data: {},
    });

  React.useEffect(() => {
    const timeout = setTimeout(firstCall, 1_000);
    return () => clearTimeout(timeout);
  }, []);

  const loggedOutComponents = (
    <>
      {isBigHeader && (
        <HStack ml={10} spacing={5}>
          <Heading
            as="a"
            size="xs"
            href="#features"
            onClick={() =>
              ev({
                ty: "landing_click",
                data: {
                  section: "features",
                },
              })
            }
          >
            Features
          </Heading>
          <Heading
            as="a"
            size="xs"
            href="#pricing"
            onClick={() =>
              ev({
                ty: "landing_click",
                data: {
                  section: "pricing",
                },
              })
            }
          >
            Pricing
          </Heading>
          <Heading
            as="a"
            size="xs"
            href="#privacy"
            onClick={() =>
              ev({
                ty: "landing_click",
                data: {
                  section: "privacy",
                },
              })
            }
          >
            Privacy
          </Heading>
          <Heading
            as="a"
            size="xs"
            href="#contact"
            onClick={() =>
              ev({
                ty: "landing_click",
                data: {
                  section: "contact",
                },
              })
            }
          >
            Contact
          </Heading>
        </HStack>
      )}
      <Spacer />
      {MANTEINANCE_MODE ? (
        <HStack ml={10} spacing={5}>
          <Tooltip label="SheetSQL is in manteinance mode, please come back shortly!">
            <Text fontSize="medium">⚙️ 👩‍🔧</Text>
          </Tooltip>
        </HStack>
      ) : (
        <SimpleGrid m={2} columns={isBigHeader ? 2 : 1} spacing={2}>
          <Button
            size="md"
            colorScheme="purple"
            onClick={(e) => {
              e.preventDefault();
              ev({
                ty: "register_intent",
                data: {
                  from: "header",
                },
              });
            }}
          >
            <SignUpButton mode="modal">Get started!</SignUpButton>
          </Button>
          <Button
            size="md"
            variant="outline"
            colorScheme="purple"
            onClick={(e) => {
              e.preventDefault();
              ev({
                ty: "login_intent",
                data: {
                  from: "header",
                },
              });
            }}
          >
            <SignInButton mode="modal">Log in</SignInButton>
          </Button>
        </SimpleGrid>
      )}
    </>
  );

  const goToLoom = () => {
    ev({
      ty: "app_click",
      data: {
        link: "loom",
      },
    });

    window.open(
      "https://www.loom.com/share/d08d6237d77e4bdaa3a7e6bd5c7e7776",
      "_blank",
    );
  };

  const goToEmail = () => {
    ev({
      ty: "app_click",
      data: {
        link: "email",
      },
    });

    window.open("mailto:taras@sheetsql.io", "_blank");
  };

  return (
    <Flex
      sx={{
        position: "sticky",
        zIndex: 100,
        top: 0,
      }}
      background="gray.50"
      borderBottom="1px"
      borderColor="gray.300"
    >
      <Container
        display="flex"
        maxW={isBigHeader ? "container.2xl" : "container.2xl"}
      >
        <HStack spacing={2} alignItems="center">
          <Image
            src="/sheetsql.webp"
            boxSize="48px"
            alt="SheetSQL logo"
            borderRadius="full"
            p={1}
          />
          <Heading as="a" size="md" href="#">
            <code>SheetSQL</code>
          </Heading>
          {signedIn && (
            <Tooltip label="Contact taras@sheetsql.io">
              <IconButton
                variant="ghost"
                size="xs"
                aria-label="Contact us"
                icon={<EmailIcon />}
                onClick={goToEmail}
              />
            </Tooltip>
          )}
          {signedIn && (
            <Tooltip label="See Loom video tutorial">
              <IconButton
                variant="ghost"
                size="xs"
                aria-label="See loom video"
                icon={<InfoIcon />}
                onClick={goToLoom}
              />
            </Tooltip>
          )}
        </HStack>
        {signedIn ? <LoggedInComponents /> : loggedOutComponents}
      </Container>
    </Flex>
  );
};

export default Header;
