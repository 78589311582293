import { useQuery } from "react-query";
import { useClerkToken } from "../providers/clerk-token";
import { getSheetFileDetails } from "../api/get-sheet-file-details";

export const useSheetFileDetails = (id: string, wsId?: number, opts?: any) => {
  const { token } = useClerkToken();

  return useQuery(
    ["SHEET_FILES", id],
    () => getSheetFileDetails(token, id, wsId),
    {
      ...opts,
      enabled: !!token && opts?.enabled,
    },
  );
};
