import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
} from "@chakra-ui/react";
import { useBlock } from "./hooks/use-block";
import BlockDetails from "./BlockDetails";

type Props = {
  blockId: string;
  isOpen: boolean;
  onClose: () => void;
};

const BlockModal = ({ blockId, isOpen, onClose }: Props) => {
  const { data: block, isLoading: isBlockLoading } = useBlock(blockId);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay
        zIndex={101}
        bg="blackAlpha.200"
        backdropFilter="blur(10px) hue-rotate(45deg)"
      />
      <ModalContent>
        <ModalHeader>Block details</ModalHeader>
        <ModalCloseButton />
        <ModalBody flex={1}>
          {isBlockLoading && <Spinner />}
          {block && <BlockDetails block={block} />}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BlockModal;
