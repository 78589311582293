import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  useToast,
  Tooltip,
  Heading,
  Spacer,
  Grid,
  GridItem,
  HStack,
  VStack,
  Card,
  Alert,
  AlertIcon,
  Text,
  IconButton,
  Link,
  Divider,
  Portal,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Input,
  Editable,
  EditableInput,
  EditablePreview,
  Container,
} from "@chakra-ui/react";
import {
  AddIcon,
  ExternalLinkIcon,
  HamburgerIcon,
  CheckIcon as ValidateIcon,
  SearchIcon as RunIcon,
  DownloadIcon as SaveIcon,
  Icon,
} from "@chakra-ui/icons";
import { MdFormatAlignJustify } from "react-icons/md";
import { previewSheetQuery } from "./api/preview-sheet-query";
import { validateQuery } from "./api/validate-query";
import { saveSheetQuery } from "./api/save-sheet-query";
import { getQueries } from "./api/get-queries";
import { saveQuery } from "./api/save-query";
import { updateQuery } from "./api/update-query";
import { UserSavedQuery } from "./api/get-query";
import DataTable from "./DataTable";
import SheetList from "./SheetAccordionList";
import { useClerkToken } from "./providers/clerk-token";
import useKeyboardShortcut from "./hooks/use-keyboard-shortcut";
import { useUserUsage } from "./hooks/use-user-usage";
import { DEFAULT_QUERY } from "./consts";

import { SheetSQLCodeMirrorEditor } from "./CodeMirrorEditor";
import ScheduleQueryButton from "./ScheduleQueryButton";
import AiQueryButton from "./AiQueryButton";

const Signed = () => {
  const toast = useToast();

  const { data: usage } = useUserUsage();

  const [queryName, setQueryName] = React.useState<string | null>(null);
  const [savedQueryId, setSavedQueryId] = React.useState<UserSavedQuery | null>(
    null,
  );

  const [automaticRun, setAutomaticRun] = React.useState<boolean>(false);

  const [query, setQuery] = React.useState<string>(DEFAULT_QUERY);

  const qc = useQueryClient();

  const { token } = useClerkToken();

  let left: string | number | null = usage
    ? usage.by_type.QUERY.limit - usage.by_type.QUERY.usage
    : null;

  const leftStr = usage?.type === "PRO" ? "∞" : left;

  const handleUnload = (event: any) => {
    const prompt = (value = "") => {
      event.preventDefault();
      event.returnValue = value;
    };

    if (!savedQueryId && query !== DEFAULT_QUERY)
      prompt("You have unsaved changes");
    if (savedQueryId && query !== savedQueryId.query)
      prompt(`The query "${savedQueryId.name}" is not saved"`);
    if (savedQueryId && queryName !== savedQueryId.name)
      prompt(`Query name "${savedQueryId.name}" is not saved`);
  };

  // Avoid user leaving without saving
  React.useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [handleUnload]);

  const maybeGetCleanQuery = () => {
    if (savedQueryId) {
      return savedQueryId.query;
    } else {
      return DEFAULT_QUERY;
    }
  };

  React.useEffect(() => {
    // Overwrite the query when the user
    // selects a saved query / create a new one
    setQuery(maybeGetCleanQuery());

    if (savedQueryId) {
      setQueryName(savedQueryId.name);
    } else {
      setQueryName(null);
    }
  }, [savedQueryId, setQueryName, setQuery]);

  const { data: queries } = useQuery(
    ["SAVED_QUERIES"],
    () => getQueries(token),
    {
      enabled: !!token,
      refetchOnWindowFocus: false,
    },
  );

  const { isLoading: isSavingQuery, mutate: saveQueryStrMt } = useMutation(
    saveQuery,
    {
      onError: (err: Error) => {
        toast({
          title: "Could not save query",
          description: err.toString(),
          duration: 10_000,
          status: "error",
          isClosable: true,
        });
      },
      onSuccess: (success) => {
        qc.invalidateQueries(["SAVED_QUERIES"]);
        setSavedQueryId(success);
      },
    },
  );

  const { isLoading: isUpdatingQuery, mutate: updateQueryStrMt } = useMutation(
    updateQuery,
    {
      onError: (err: Error) => {
        toast({
          title: "Could not update query",
          description: err.toString(),
          duration: 10_000,
          status: "error",
          isClosable: true,
        });
      },
      onSuccess: (success) => {
        qc.invalidateQueries(["SAVED_QUERIES"]);
        setSavedQueryId(success);
      },
    },
  );

  const {
    isLoading: isMutating,
    mutate,
    data: previewQuery,
    reset: resetPreview,
  } = useMutation(previewSheetQuery, {
    onError: (err: Error) => {
      toast({
        title: "Could not run query",
        description: err.toString(),
        duration: 10_000,
        status: "error",
        isClosable: true,
      });
    },
    onSuccess: () => {
      qc.invalidateQueries(["USER_USAGE"]);
    },
  });

  const {
    isLoading: isSaving,
    mutate: saveQueryMt,
    data: savedQuery,
    reset: resetSave,
  } = useMutation(saveSheetQuery, {
    onError: (err: Error) => {
      toast({
        title: "Could not run and save query",
        description: err.toString(),
        duration: 10_000,
        status: "error",
        isClosable: true,
      });
    },
    onSuccess: () => {
      qc.invalidateQueries(["SHEET_FILES"]);
      qc.invalidateQueries(["USER_USAGE"]);
    },
  });

  const {
    isLoading: isValidating,
    mutate: validateQueryMut,
    mutateAsync: validateQueryMutAsync,
  } = useMutation(validateQuery, {
    onSuccess: (resp) => {
      if (resp.valid) {
        toast({
          title: "Valid query",
          duration: 2_000,
          status: "success",
          isClosable: true,
        });
      } else {
        toast({
          title: "Invalid query",
          description: resp.details,
          duration: 10_000,
          status: "error",
          isClosable: true,
        });
      }
    },
    onError: (err: Error) => {
      toast({
        title: "Could not validate query",
        description: err.toString(),
        duration: 10_000,
        status: "error",
        isClosable: true,
      });
    },
  });

  const handlePreview = (type: "run" | "save") => {
    resetPreview();
    resetSave();

    const postData = {
      token,
      query,
    };

    if (type === "run") {
      mutate(postData);
    } else {
      saveQueryMt({
        ...postData,
        ...(savedQueryId ? { name: savedQueryId.name } : {}),
      });
    }
  };

  React.useEffect(() => {
    if (automaticRun) {
      mutate({
        token,
        query,
      });

      setAutomaticRun(false);
    }
  }, [token, automaticRun, handlePreview, query]);

  const handleValidate = () => {
    if (!query.length) return;

    const postData = {
      token,
      query,
    };

    validateQueryMut(postData);
  };

  const handleValidateAndFormat = async () => {
    if (!query.length) return;

    const postData = {
      token,
      query,
    };

    const resp = await validateQueryMutAsync(postData);

    if (resp.valid && resp.formatted) {
      setQuery(resp.formatted);
    }
  };

  const handleQueryStrSave = () => {
    if (!queryName?.length) return;

    // If the user has a saved query selected
    // then the save action should be an update instead
    if (savedQueryId) {
      updateQueryStrMt({
        token,
        id: savedQueryId.id,
        query: {
          name: queryName,
          query,
        },
      });
    } else {
      saveQueryStrMt({
        token,
        query: {
          name: queryName,
          query,
        },
      });
    }
  };

  useKeyboardShortcut(
    ({ code, metaKey, ctrlKey, shiftKey }) => {
      if (!metaKey && !ctrlKey) return;

      if (code === "Enter") {
        handlePreview(shiftKey ? "save" : "run");
      }
    },
    {
      code: ["Enter"],
    },
  );

  return (
    <Container maxW="container.2xl" mt={2}>
      <Grid templateColumns="repeat(12, 1fr)" gap={6}>
        <GridItem colSpan={[12, 12, 1, 3]}>
          <SheetList />
        </GridItem>
        <GridItem colSpan={[12, 12, 11, 9]}>
          <Grid templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={12} mt={2}>
              <VStack spacing={1} alignItems="start">
                <Heading size="md">Get started!</Heading>
                <Text fontSize="xs">
                  Write your <code>SheetSQL</code> below, and run your query
                  joining various spreadsheets and worksheets!{" "}
                  <code>SheetSQL</code> offers a really simple way to do so,
                  just begin typing the name of the sheet and the editor will
                  autocomplete the sheet name for you.
                </Text>
              </VStack>
            </GridItem>
            <GridItem colSpan={12}>
              <HStack spacing={2} mb={1}>
                <Editable
                  placeholder="Untitled query..."
                  w="100%"
                  {...(queryName ? { value: queryName } : {})}
                  onChange={(e) => setQueryName(e)}
                >
                  <EditablePreview />
                  <Input as={EditableInput} />
                </Editable>
                <Spacer />
                <Tooltip label="New query">
                  <IconButton
                    isDisabled={!savedQueryId}
                    onClick={() => setSavedQueryId(null)}
                    variant="ghost"
                    size="xs"
                    fontSize="12px"
                    aria-label="New query"
                    icon={<AddIcon />}
                  />
                </Tooltip>
                <Tooltip
                  label={
                    queryName ? "Save query" : "Set a query name to save it"
                  }
                >
                  <IconButton
                    isDisabled={!queryName || isSavingQuery || isUpdatingQuery}
                    isLoading={isSavingQuery || isUpdatingQuery}
                    onClick={handleQueryStrSave}
                    variant="ghost"
                    size="xs"
                    fontSize="12px"
                    aria-label="Save query"
                    icon={<SaveIcon />}
                  />
                </Tooltip>
                <Menu>
                  <Tooltip
                    label={
                      queries?.length
                        ? "Saved queries list"
                        : "No queries saved"
                    }
                  >
                    <MenuButton
                      isDisabled={!queries?.length}
                      as={IconButton}
                      aria-label="Options"
                      variant="ghost"
                      size="xs"
                      fontSize="12px"
                      icon={<HamburgerIcon />}
                    />
                  </Tooltip>
                  <Portal>
                    <MenuList zIndex={1000}>
                      {queries?.map((q) => (
                        <MenuItem
                          isDisabled={savedQueryId?.id === q.id}
                          key={q.id}
                          onClick={() => setSavedQueryId(q)}
                        >
                          {q.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Portal>
                </Menu>
              </HStack>
              <Card>
                <SheetSQLCodeMirrorEditor
                  value={query}
                  onChange={(v) => v && setQuery(v)}
                />
              </Card>
            </GridItem>
            <GridItem colSpan={12}>
              <HStack spacing={2}>
                <Spacer />
                <AiQueryButton
                  query={savedQueryId?.query || undefined}
                  onChange={(v) => v && setQuery(v)}
                  onRun={() => setAutomaticRun(true)}
                />
              </HStack>
            </GridItem>
            <GridItem colSpan={12}>
              <HStack spacing={2}>
                <Tooltip label="Validate query">
                  <IconButton
                    isDisabled={!query.length || isValidating}
                    isLoading={isValidating}
                    onClick={handleValidate}
                    fontSize="12px"
                    aria-label="Validate query"
                    icon={<ValidateIcon />}
                  />
                </Tooltip>
                <Tooltip label="Validate and format query">
                  <IconButton
                    isDisabled={!query.length || isValidating}
                    isLoading={isValidating}
                    onClick={handleValidateAndFormat}
                    fontSize="12px"
                    aria-label="Validate and format query"
                    icon={
                      <Icon
                        as={MdFormatAlignJustify}
                        boxSize={4}
                        color="gray"
                      />
                    }
                  />
                </Tooltip>
                <Spacer />
                <ScheduleQueryButton query={savedQueryId || undefined} />
                <Tooltip
                  label={`Run query, or use ⌘ / Ctrl + ↵ (${leftStr} credits left)`}
                  placement="bottom-start"
                >
                  <IconButton
                    isDisabled={
                      isMutating ||
                      !query.length ||
                      isValidating ||
                      isSaving ||
                      (usage?.type !== "PRO" && (left || 0) <= 0)
                    }
                    isLoading={isMutating}
                    onClick={() => handlePreview("run")}
                    fontSize="12px"
                    aria-label="Run query"
                    icon={<RunIcon />}
                  />
                </Tooltip>
                {/* TODO(taras): Offer user name selection */}
                <Tooltip
                  label={`Run and save query, or use ⌘ / Ctrl + ⇧ + ↵ (${leftStr} credits left)`}
                  placement="bottom-start"
                >
                  <IconButton
                    isDisabled={
                      isSaving ||
                      !query.length ||
                      isValidating ||
                      isMutating ||
                      (usage?.type !== "PRO" && (left || 0) <= 0)
                    }
                    isLoading={isSaving}
                    onClick={() => handlePreview("save")}
                    fontSize="12px"
                    aria-label="Run query and save results to sheet"
                    icon={<SaveIcon />}
                  />
                </Tooltip>
              </HStack>
            </GridItem>
            <GridItem colSpan={12}>
              <Divider />
            </GridItem>
            {savedQuery && (
              <GridItem colSpan={12}>
                <VStack spacing={2}>
                  <Alert status="success">
                    <AlertIcon />
                    <Text fontSize="xs">
                      Query successfully exported at{" "}
                      <Link
                        color="teal.600"
                        href={savedQuery.save_data.url}
                        isExternal
                      >
                        {savedQuery.save_data.name}
                        <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Text>
                  </Alert>
                </VStack>
              </GridItem>
            )}
            <GridItem colSpan={12}>
              <Heading size="xs">
                {previewQuery ? "Data" : "Run a query to preview data..."}
              </Heading>
            </GridItem>
            <GridItem colSpan={12}>
              {savedQuery && <DataTable data={savedQuery} />}
              {previewQuery && <DataTable data={previewQuery} />}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default Signed;
