import { Op } from "../blocks.types";
import { API_URL } from "./auth";

export const deleteOp = async ({
  token,
  id,
}: {
  token: string | null;
  id: string;
}): Promise<Op> => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const resp = await fetch(API_URL + `/api/b/ops/${id}`, {
    method: "DELETE",
    headers,
  });

  if (resp.status !== 200) {
    throw new Error(
      `Could not delete operation due to ${resp.status} HTTP error code`,
    );
  }

  return resp.json();
};
